import axios from 'axios';
import AxiosUtil from '../utils/axiosUtil';

export const AuthService = (function () {
  let instance;

  function init() {
    return {
      login: async function (loginId, password) {
        return await AxiosUtil.Async.post('/apis/token/issue', {
          loginId,
          password,
        });
      },
      logout: async function () {
        return await axios.post('/apis/token/withdraw');
      },
      refreshToken: async function () {
        return await AxiosUtil.Async.post('/apis/token/refresh', {});
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();
