import React, { forwardRef } from 'react';

import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';

export const GroupTitledDropdown = forwardRef((props, ref) => {
  const {
    title = '',
    value,
    onChange,
    readOnly = false,
    disabled = false,
    placeholder,
    className = '',
    options = [],
    optionLabel = 'label',
    optionValue = 'value',
    classNameProps,
    filter = false,
  } = props;

  return (
    <div className="p-inputgroup h-full">
      <span
        className={`${
          classNameProps ? 'input-border-primary' : 'p-inputgroup-addon'
        } h-full w-full font-medium text-center min-w-3 w-4 max-w-5`}
        style={{
          display: 'table',
        }}
      >
        <span
          style={{
            display: 'table-cell',
            verticalAlign: 'middle',
            wordBreak: 'keep-all',
          }}
        >
          {title}
        </span>
      </span>
      <Dropdown
        // ref={ref}
        className={classNames(
          className,
          readOnly && (className === '' ? 'bg-gray-100' : className)
        )}
        filter={filter}
        optionLabel={optionLabel}
        optionValue={optionValue}
        readOnly={readOnly}
        disabled={disabled}
        value={value}
        options={options}
        placeholder={placeholder}
        onChange={onChange && ((e) => onChange(e))}
      />
    </div>
  );
});
