import axios from 'axios';
import AxiosUtil from '../utils/axiosUtil';
import { BaseService } from './BaseService';
import _ from 'lodash';

const BASE_URI = '/apis/partners';
export const PartnerService = (function () {
  let instance;
  const baseService = BaseService.getInstance(BASE_URI, 'partners');

  function init() {
    return {
      ...baseService,
      register: async function (data) {
        return await AxiosUtil.Async.post(BASE_URI, data);
      },
      update: async function (data) {
        return await AxiosUtil.Async.put(BASE_URI, data);
      },
      list: async function (params) {
        const queryString = Object.entries(params)
          .map((e) => e.join('='))
          .join('&');

        return AxiosUtil.Async.get(BASE_URI, queryString);
      },
      getData: async function (partnerId) {
        return AxiosUtil.Async.get(BASE_URI + `/${partnerId}`);
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();

export const Partner = {
  delete: async (id) => {
    const res = await axios.delete(`${BASE_URI}/${id}`);
    return res.data.data;
  },
  getDetailData: async (id) => {
    const res = await axios.get(`${BASE_URI}/${id}`);
    return res.data.data;
  },
  postPartner: async (data, files) => {
    const res = await AxiosUtil.Async.multipart(`${BASE_URI}`, data, files);
    return res.data;
  },

  putPartner: async (data, files) => {
    const res = await AxiosUtil.Async.updateMultipart(
      `${BASE_URI}`,
      data,
      files
    );
    return res.data;
  },

  nearList: async (data) => {
    const res = await axios.get(`${BASE_URI}/search`, {
      params: data,
    });
    return res.data;
  },

  exportToExcel: async (data) => {
    try {
      const res = await axios.get(`apis/excel/partners`, {
        params: data,
        responseType: 'blob',
      });
      const contentDisposition = res.headers['content-disposition'];
      const [, fileNameData] = _.split(contentDisposition, ';');
      const [, fileName] = _.split(fileNameData, '=');

      const url = window.URL.createObjectURL(
        new Blob([res.data], {
          type: res.headers['content-type'],
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        decodeURIComponent(_.replace(fileName, /"/g, ''))
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.cerp.toast.clear();
      window.cerp.toast.success('엑셀 파일 다운로드에 성공했습니다.');
    } catch (error) {
      window.cerp.toast.clear();
      window.cerp.toast.error('엑셀 다운로드에 실패했습니다.');
    }
  },
};
