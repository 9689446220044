import _ from 'lodash';
import dayjs from 'dayjs';

const EW_TARGET_SHOPS = [
  {
    shopCode: 'B61',
    shopName: '부산신평',
  },
];

const EW_CERTIFICATE_DATAS = [
  // {
  //   name: 'inspectorName',
  //   objName: 'inspector',
  //   fieldName: 'enterpriseName',
  //   title: '점검업체이름',
  //   insuranceCompanyCode: [13],
  //   pos: {
  //     // insuranceCode: [top, left]
  //     13: [78, 610],
  //   },
  //   getValue: (totalValues) => {
  //     return _.get(totalValues, 'inspector.enterpriseName');
  //   },
  // },
  {
    name: 'VHRNO',
    objName: 'checkData',
    fieldName: 'VHRNO',
    title: '차량번호',
    insuranceCompanyCode: [13],
    pos: {
      13: [510, 170],
    },
    getValue: (totalValues) => {
      return _.get(totalValues, 'checkData.VHRNO');
    },
  },
  {
    name: 'VIN',
    objName: 'checkData',
    fieldName: 'VIN',
    title: '차대번호전체',
    insuranceCompanyCode: [13],
    pos: {
      13: [510, 455],
    },
    getValue: (totalValues) => {
      if (_.get(totalValues, 'checkData.VIN').length === 17) {
        return _.get(totalValues, 'checkData.VIN');
      } else {
        return (
          _.get(totalValues, 'vehicleInformation.VIN') +
          _.get(totalValues, 'vehicleInformation.vinRemain')
        );
      }
    },
  },
  {
    name: 'CNM',
    objName: 'vehicleInformation',
    fieldName: 'CNM',
    title: '차량명',
    insuranceCompanyCode: [13],
    pos: {
      13: [535, 170],
    },
    getValue: (totalValues) => {
      return `${_.get(totalValues, 'vehicleInformation.CNM')}${_.get(
        totalValues,
        'vehicleInformation.cnmDetail'
      )}`;
    },
  },
  {
    name: 'statementNumber',
    objName: 'checkData',
    fieldName: 'PRFOMNC_CHCK_NO',
    title: '성능점검번호',
    insuranceCompanyCode: [13],
    pos: {
      13: [535, 455],
    },
    getValue: (totalValues) => {
      return _.get(totalValues, 'checkData.PRFOMNC_CHCK_NO');
    },
  },
  {
    name: 'madeAt',
    objName: 'vehicleInformation',
    fieldName: 'madeAt',
    title: '국/외산',
    insuranceCompanyCode: [13],
    pos: {
      13: [560, 170],
    },
    getValue: (totalValues) => {
      const label = {
        D: '국산',
        F: '외산',
      };
      return `${_.get(label, _.get(totalValues, 'vehicleInformation.madeAt'))}`;
    },
  },
  {
    name: 'CHCK_DE',
    objName: 'checkData',
    fieldName: 'CHCK_DE',
    title: '성능점검일자',
    insuranceCompanyCode: [13],
    pos: {
      13: [560, 455],
    },
    getValue: (totalValues) => {
      return dayjs(_.get(totalValues, 'checkData.CHCK_DE')).format(
        'YYYY-MM-DD'
      );
    },
  },
  // {
  //   name: 'callCenter',
  //   title: '상담센터',
  //   insuranceCompanyCode: [13],
  //   pos: {
  //     13: [790, 60],
  //   },
  //   styleClass: 'text-2xl',
  //   getValue: () => {
  //     return '사고접수 및 상담센터 : 1600-3528';
  //   },
  // },
];

export { EW_CERTIFICATE_DATAS, EW_TARGET_SHOPS };
