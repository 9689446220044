import React, { useEffect, useState, useMemo } from 'react';
import * as Modal from '../../components/Atoms/Modal';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import ReportDialog from './ReportDialog';
import { useQuery } from 'react-query';
import { Claim } from '../../services/ClaimService';
import dayjs from 'dayjs';
import RangeCalendar from '../../components/Common/RangeCalendar';
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
  useResetRecoilState,
} from 'recoil';
import { myInfoSelector } from '../../recoil/selectors';
import {
  searchSaveState,
  shopListState,
  touchUIState,
} from '../../recoil/atoms';
import { useHistory } from 'react-router-dom';
import { BlockUI } from 'primereact/blockui';
import { CommonUtil } from '../../utils/commonUtil';
import { ServiceProvider } from '../../services/index';
import { Checkbox } from 'primereact/checkbox';
import { claimSearchState } from '../../recoil/atoms';
import { Shop } from '../../services/CodeService.js';

const partnerService = ServiceProvider.partner;

const ApprovalList = () => {
  const history = useHistory();
  const touchUI = useRecoilValue(touchUIState);
  const [showReportMoal, setShowReportModal] = useState(false);
  // const [showSmsMoal, setShowSmsModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const today = dayjs();
  const startDate = dayjs('20240701').toDate();
  const endDate = today;
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 100,
    page: 0,
  });
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const partner = myInfoLoadable.contents.roleCode === 'D_ADM';
  const aid = myInfoLoadable.contents.associationInfo?.associationId;

  const [customerInfo, setCustomerInfo] = useState({
    carNbr: '',
    carVIN: '',
    newCarNbr: '',
    customerName: '',
    customerContact: '',
    association: { value: '' },
    warranty: { value: '' },
    addType: { value: '' },
    receiptDate: null,
  });

  const [carDetailData, setCarDetailData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const defaultData = {
    startDate: dayjs(startDate).format('YYYYMMDD'),
    endDate: dayjs(endDate).format('YYYYMMDD'),
    page: lazyState.page + 1,
    checkType: ['Z06', 'Z17'],
    size: lazyState.rows,
    orderType: 'D',
  };

  const [searchRadio, setSearchRadio] = useState({
    inputId: 'customer',
    value: 'customer',
  });

  const [searchMode, setSearchMode] = useState(false);
  const [searchTotal, setSearchTotal] = useState(0);

  const { data, isLoading } = useQuery({
    queryKey: ['list', searchMode, lazyState.page, lazyState.rows],
    queryFn: () => Claim.getList(defaultData),
    enabled: !searchMode && !!aid,
    refetchInterval: 30000, // 30초 간격
  });

  const setSearchState = useSetRecoilState(claimSearchState);
  const searchState = useRecoilValue(claimSearchState);
  const resetSearchState = useResetRecoilState(claimSearchState);
  const setSearchSaveState = useSetRecoilState(searchSaveState);
  const isSearchSave = useRecoilValue(searchSaveState);
  const resetSearchSaveState = useResetRecoilState(searchSaveState);

  const [approvalSearchState, setApprovalSearchState] = useState({
    startDate: dayjs('20240701').toDate(),
    endDate: dayjs().toDate(),
    shopId: null,
    carNbr: null,
    customerName: null,
    customerContact: null,
    checkType: null,
    receiptName: null,
    completeName: null,
    orderType: 'D',
  });

  const resetApprovalSearchState = () => {
    setApprovalSearchState({
      startDate: dayjs('20240701').toDate(),
      endDate: dayjs().toDate(),
      shopId: null,
      carNbr: null,
      customerName: null,
      customerContact: null,
      checkType: null,
      receiptName: null,
      completeName: null,
      orderType: 'D',
    });
  };
  const getSearchApprovalList = async () => {
    try {
      setSearchLoading(true);
      // 상태값이 null이 아닌 것만 params 데이터로 넣음
      const filteredParams = Object.keys(approvalSearchState).reduce(
        (acc, key) => {
          if (
            approvalSearchState[key] !== null &&
            approvalSearchState[key] !== ''
          ) {
            acc[key] = approvalSearchState[key];
          }
          return acc;
        },
        {}
      );

      const data = await Claim.getList({
        ...filteredParams,
        startDate: dayjs(approvalSearchState.startDate).format('YYYYMMDD'),
        endDate: dayjs(approvalSearchState.endDate).format('YYYYMMDD'),
        checkType: !approvalSearchState.checkType
          ? ['Z06', 'Z17']
          : approvalSearchState.checkType,
        page: lazyState.page + 1,
        size: lazyState.rows,
      });
      if (data) {
        setTableData(data.list);
        setSearchTotal(data.total);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSearchLoading(false);
    }
  };

  useEffect(() => {
    getSearchApprovalList();
    setSearchMode(true);
  }, [lazyState.page]);

  useEffect(() => {
    if (data && !isLoading && !searchMode && !isSearchSave) {
      setTableData(data.list);
      setSearchMode(false);
    }
  }, [data, isLoading, searchMode, isSearchSave]);

  const setShopListState = useSetRecoilState(shopListState);
  const shopList = useRecoilValue(shopListState);

  const getShopList = async (eids) => {
    try {
      const data = await Shop.getList({ eids: eids });
      if (data) {
        setShopListState(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!!myInfoLoadable.contents.associationInfo) {
      getShopList(myInfoLoadable.contents.associationInfo.associationId);
    }
  }, [myInfoLoadable.contents.associationInfo]);

  const handleCustomerInfoInput = (event) => {
    const target = event.target;
    const { name, value } = target;
    setCustomerInfo({ ...customerInfo, [name]: value });
  };

  const handleSearchInput = (event) => {
    const target = event.target;
    const { name, value } = target;
    setApprovalSearchState({ ...approvalSearchState, [name]: value });
  };

  const handleRadio = (event) => {
    const target = event.target;
    setSearchRadio({
      inputId: target.name,
      value: target.value,
    });
  };

  const resetSearch = () => {
    setSearchRadio({
      inputId: 'customer',
      value: 'customer',
    });
    setSearchMode(false);
    setTodaySearch(false);
    setTodaySearchList([]);
    setTodayUpdateSearch(false);
    setTodayUpdateList([]);
    setSearchTotal(0);
    resetSearchState();
    resetSearchSaveState();
    resetApprovalSearchState();
  };

  useEffect(() => {
    if (customerInfo.carNbr === '') {
      setCustomerInfo({
        ...customerInfo,
        association: { value: '' },
        warranty: { value: '' },
      });
    }
  }, [customerInfo.carNbr]);

  const [partnerAllList, setPartnerAllList] = useState([]);
  const getPartnerList = async (aid) => {
    try {
      const data = await partnerService.list({
        aids: aid,
        page: 1,
        size: 200,
        useYN: 'Y',
      });
      if (data) {
        setPartnerAllList(data.data.list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!!aid && !partner) {
      getPartnerList(aid);
    }
  }, [aid, partner]);

  const [todaySearch, setTodaySearch] = useState(false);
  const [todaySearchList, setTodaySearchList] = useState([]);
  const [todayUpdateSearch, setTodayUpdateSearch] = useState(false);
  const [todayUpdateList, setTodayUpdateList] = useState([]);

  useEffect(() => {
    const today = dayjs(new Date()).format('YYYYMMDD');
    const list = tableData.filter((el) => el.receiptDate.includes(today));
    if (!!todaySearch) {
      setTodaySearchList(list);
    } else {
      setTodaySearchList([]);
    }
  }, [todaySearch, tableData]);

  useEffect(() => {
    const today = dayjs(new Date()).format('YYYY-MM-DD');
    const list = tableData.filter((el) => el.modTime?.includes(today));
    if (!!todayUpdateSearch) {
      setTodayUpdateList(list);
    } else {
      setTodayUpdateList([]);
    }
  }, [tableData, todayUpdateSearch]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setSearchMode(true);
      getSearchApprovalList();
    }
  };

  const [exporting, setExporting] = useState(false);
  const exportToExcel = async () => {
    setExporting(true);

    const filteredParams = Object.keys(approvalSearchState).reduce(
      (acc, key) => {
        if (
          approvalSearchState[key] !== null &&
          approvalSearchState[key] !== ''
        ) {
          acc[key] = approvalSearchState[key];
        }
        return acc;
      },
      {}
    );

    await Claim.exportToExcel({
      ...filteredParams,
      startDate: dayjs(approvalSearchState.startDate).format('YYYYMMDD'),
      endDate: dayjs(approvalSearchState.endDate).format('YYYYMMDD'),
      checkType:
        approvalSearchState.checkType === null
          ? ['Z06', 'Z17']
          : approvalSearchState.checkType,
      page: lazyState.page + 1,
      size: lazyState.rows,
    });
    setExporting(false);
  };

  return (
    <div>
      <BlockUI
        template={
          loading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
        // blocked={loading}
      ></BlockUI>
      {partner ? null : (
        <Panel className="shadow-1 mt-3">
          <div className="grid">
            <div className="col-12 md:col-8 lg:col-2">
              <div className="mb-2 font-bold">업무처리상태</div>
              <span onKeyDown={handleKeyDown}>
                <Dropdown
                  value={approvalSearchState.checkType}
                  onChange={(e) => {
                    // setCheckType(e.value);
                    setApprovalSearchState((ps) => ({
                      ...ps,
                      checkType: e.target.value,
                    }));
                  }}
                  options={STATUS_LIST}
                  optionLabel="label"
                  placeholder="상태 선택"
                  className="w-full"
                />
              </span>
            </div>
            <div className="col-12 md:col-8 lg:col-4">
              <div className="mb-2 font-bold">기간</div>
              <RangeCalendar
                startDate={approvalSearchState.startDate}
                endDate={approvalSearchState.endDate}
                onStartDateChanged={(date) => {
                  setApprovalSearchState((ps) => ({
                    ...ps,
                    startDate: date,
                  }));
                }}
                onEndDateChanged={(date) => {
                  setApprovalSearchState((ps) => ({
                    ...ps,
                    endDate: date,
                  }));
                }}
                touchUI={touchUI}
                showNavigators={true}
              />
            </div>
            <div className="col-12 md:col-8 lg:col-2">
              <div className="mb-2 font-bold">성능점검장</div>
              <span onKeyDown={handleKeyDown}>
                <Dropdown
                  value={approvalSearchState.shopId}
                  onChange={(e) => {
                    setApprovalSearchState((ps) => ({
                      ...ps,
                      shopId: e.target.value,
                    }));
                  }}
                  options={shopList}
                  optionLabel="label"
                  placeholder="점검장 선택"
                  className="w-full"
                />
              </span>
            </div>
            <div className="col-12 md:col-8 lg:col-2">
              <div className="mb-2 font-bold">접수일자 기준</div>
              <span onKeyDown={handleKeyDown}>
                <Dropdown
                  value={approvalSearchState.orderType}
                  onChange={(e) => {
                    setApprovalSearchState((ps) => ({
                      ...ps,
                      orderType: e.target.value,
                    }));
                  }}
                  options={[
                    { value: 'D', label: '내림차순' },
                    { value: 'A', label: '오름차순' },
                  ]}
                  optionLabel="label"
                  placeholder="정렬순서 선택"
                  className="w-full"
                />
              </span>
            </div>
          </div>

          <div className="grid mt-2">
            <div className="col-6 md:col-2 col">
              <div className="mb-2 font-bold">이름</div>
              <InputText
                value={approvalSearchState.customerName ?? ''}
                onChange={(e) => handleSearchInput(e)}
                onKeyDown={handleKeyDown}
                className="w-full"
                name={
                  searchRadio.inputId === 'customer'
                    ? 'customerName'
                    : 'partnerName'
                }
                placeholder={
                  searchRadio.inputId === 'customer'
                    ? '고객명 입력'
                    : '진단점명 입력'
                }
              />
            </div>
            <div className="col-6 md:col-2 col">
              <div className="mb-2 font-bold">연락처</div>
              <InputText
                value={approvalSearchState.customerContact ?? ''}
                onChange={(e) => handleSearchInput(e)}
                onKeyDown={handleKeyDown}
                className="w-full"
                name={
                  searchRadio.inputId === 'customer'
                    ? 'customerContact'
                    : 'partnerContact'
                }
                placeholder={
                  searchRadio.inputId === 'customer'
                    ? '고객 연락처 입력'
                    : '진단점 연락처 입력'
                }
              />
            </div>
            <div className="col-6 md:col-2 col">
              <div className="mb-2 font-bold">차량번호</div>
              <InputText
                value={approvalSearchState.carNbr ?? ''}
                onChange={(e) => handleSearchInput(e)}
                onKeyDown={handleKeyDown}
                className="w-full"
                name="carNbr"
                placeholder="차량번호 입력"
              />
            </div>
            <div className="col-6 md:col-2 col">
              <div className="mb-2 font-bold">최초 작성자</div>
              <InputText
                value={approvalSearchState.receiptName ?? ''}
                onChange={(e) => handleSearchInput(e)}
                onKeyDown={handleKeyDown}
                className="w-full"
                name="receiptName"
                placeholder="최초 작성자 입력"
              />
            </div>
            <div className="col-6 md:col-2 col">
              <div className="mb-2 font-bold">최종 작성자</div>
              <InputText
                value={approvalSearchState.completeName ?? ''}
                onChange={(e) => handleSearchInput(e)}
                onKeyDown={handleKeyDown}
                className="w-full"
                name="completeName"
                placeholder="최종 작성자 입력"
              />
            </div>
          </div>
          <div className="flex gap-4 mt-3">
            <Divider />
          </div>
          <div className="flex justify-content-end md:flex-row flex-column">
            {/* <div className="flex gap-4">
              <div className="flex align-items-center">
                <Checkbox
                  inputId="today"
                  onChange={(e) => setTodaySearch(e.checked)}
                  checked={todaySearch}
                  disabled={todayUpdateSearch}
                />
                <label className="ml-2" htmlFor="today">
                  당일 접수건 조회
                </label>
              </div>
              <div className="flex align-items-center">
                <Checkbox
                  inputId="update"
                  onChange={(e) => setTodayUpdateSearch(e.checked)}
                  checked={todayUpdateSearch}
                  disabled={todaySearch}
                />
                <label className="ml-2" htmlFor="update">
                  당일 업데이트건 조회
                </label>
              </div>
              <div className="flex align-items-center">
                <Checkbox
                  inputId="searchSave"
                  onChange={(e) => setSearchSaveState(e.checked)}
                  checked={isSearchSave}
                  // disabled={todaySearch}
                />
                <label className="ml-2" htmlFor="searchSave">
                  검색조건 기억하기
                </label>
              </div>
            </div> */}
            <div className="flex justify-content-end md:mt-0 mt-2">
              <Button
                label="엑셀 다운로드"
                className="p-button-outlined mr-1"
                icon="pi pi-download"
                disabled={exporting || tableData.length === 0}
                loading={exporting}
                onClick={async () => await exportToExcel()}
              />
              <Button
                label="검색조건 초기화"
                icon="pi pi-undo"
                className="p-button-outlined mr-1"
                onClick={resetSearch}
              />
              <Button
                label="검색"
                icon="pi pi-search"
                onClick={() => {
                  setSearchMode(true);
                  getSearchApprovalList();
                }}
                loading={searchLoading}
              />
            </div>
          </div>
        </Panel>
      )}
      <Panel className="shadow-1 mt-3 mb-3">
        <DataTable
          value={
            todaySearch
              ? todaySearchList
              : todayUpdateSearch
              ? todayUpdateList
              : tableData
          }
          lazy
          onPage={(e) => setlazyState(e)}
          rows={lazyState.rows}
          first={lazyState.first}
          totalRecords={
            todaySearch
              ? todaySearchList.length
              : todayUpdateSearch
              ? todayUpdateList.length
              : searchMode
              ? searchTotal
              : !!data?.total
              ? data?.total
              : 0
          }
          paginator
          responsiveLayout="scroll"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate={
            todaySearch || todayUpdateSearch || searchMode || isSearchSave
              ? '전체 {totalRecords}건'
              : '전체 {totalRecords}건 중 {first} ~ {last}번'
          }
          rowsPerPageOptions={[20, 30, 50, 100, 500, 1000, 5000, 10000]}
          showGridlines
          emptyMessage="데이터가 없습니다."
          loading={isLoading || searchLoading}
          onSelectionChange={({ value }) => {
            setSelectedRows(value);
            history.push({
              pathname: `/compensation/approval/claim/detail/${value.dataId}`,
            });
          }}
          dataKey="id"
          selection={selectedRows}
          selectionMode="single"
          stripedRows
        >
          <Column
            align={'center'}
            field="dtn"
            header="순번"
            body={(dataId, column) => (
              <div className="w-2rem">{column.rowIndex + 1}</div>
            )}
          />

          <Column
            align={''}
            field={'claimStatus'}
            header={'업무처리상태'}
            body={({ claimStatus }) => {
              return (
                <div className="w-14rem">
                  {STATUS_LIST.find((el) => el.value === claimStatus)?.label}
                </div>
              );
            }}
          />
          <Column
            align={'center'}
            field={'receiptDate'}
            header={'접수일자'}
            body={({ receiptDate }) => (
              <div className="w-8rem">
                {CommonUtil.Formatter.stringToDayForm(receiptDate)}
              </div>
            )}
          />
          <Column
            align={'center'}
            field={'shopId'}
            header={'성능점검장'}
            body={({ shopId }) => {
              const item = shopList.find((el) => el.value === shopId);
              return <div className="w-8rem">{item?.label?.split(']')[1]}</div>;
            }}
          />
          {TABLE_COLUMNS.map((col, i) => (
            <Column
              align={'center'}
              key={i}
              field={col.field}
              header={<div className="w-10rem">{col.header}</div>}
            />
          ))}
          <Column
            align={'center'}
            field={'customerContact'}
            header={'고객연락처'}
            body={({ customerContact }) => (
              <div className="w-8rem">
                {CommonUtil.Formatter.phone(customerContact)}
              </div>
            )}
          />
          <Column
            align={'center'}
            field={'carAddress'}
            header={'고객차량위치'}
            body={({ carAddress }) => (
              <div className="w-8rem">{carAddress}</div>
            )}
          />
          <Column
            align={'center'}
            field={'note'}
            header={'접수증상'}
            body={({ note }) => <div className="w-18rem">{note}</div>}
          />
          {partner ? null : (
            <Column
              align={'center'}
              field={'partnerId'}
              header={'진단점'}
              body={({ partnerInfo }) => {
                const ids = [];
                partnerInfo.map((el) => ids.push(el.partnerId));
                const data = [];
                ids &&
                  ids.forEach((id) =>
                    data.push(
                      partnerAllList.filter((el) => id === el.partnerId)
                    )
                  );
                return (
                  <div className="w-10rem" style={{ whiteSpace: 'pre-wrap' }}>
                    {data
                      ?.flat()
                      .map(
                        (el, idx) =>
                          `${el.partnerName}${
                            data.length - 1 === idx ? '' : '\r\n'
                          }`
                      )}
                  </div>
                );
              }}
            />
          )}
          {partner ? null : (
            <Column
              align={'center'}
              field={'partnerId'}
              header={'진단점 연락처'}
              body={({ partnerInfo }) => {
                const ids = [];
                partnerInfo.map((el) => ids.push(el.partnerId));
                const data = [];
                ids &&
                  ids.forEach((id) =>
                    data.push(
                      partnerAllList.filter((el) => Number(id) === el.partnerId)
                    )
                  );
                return (
                  <div className="w-9rem">
                    {data?.flat().map((el, idx) => {
                      return `${CommonUtil.Formatter.cellphone(el.cellphone)}${
                        data.length - 1 === idx ? '' : '\r\n'
                      }`;
                    })}
                  </div>
                );
              }}
            />
          )}
          {partner ? null : (
            <Column
              align={'center'}
              field={'receipName'}
              header={'최초 작성자'}
              body={({ receiptName }) => (
                <div className="w-6rem">{receiptName}</div>
              )}
            />
          )}
          {partner ? null : (
            <Column
              align={'center'}
              field={'completeName'}
              header={'최종 작성자'}
              body={({ completeName }) => (
                <div className="w-6rem">{completeName}</div>
              )}
            />
          )}
          {partner ? null : (
            <Column
              align={'center'}
              field={'insurancePaymentAmt'}
              header={'총지급액'}
              body={({ insurancePaymentAmt }) => (
                <div className="w-6rem">
                  {!!insurancePaymentAmt
                    ? insurancePaymentAmt
                        ?.toString()
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        ?.toString() + ' 원'
                    : '-'}
                </div>
              )}
            />
          )}
        </DataTable>
      </Panel>

      {showReportMoal && (
        <ReportDialog onHide={() => setShowReportModal(!showReportMoal)} />
      )}

      {showConfirmModal && (
        <Modal.Default
          onHide={() => setShowConfirmModal(false)}
          header={'수기입력 등록'}
          widthClass="30rem"
        >
          <div className="pb-4">
            고객정보를 다시 한번 확인해주세요.
            <br />
            <Divider />
            <strong className="text-lg">
              - 차량번호 : {customerInfo.carNbr}
            </strong>
          </div>
          <div className="flex gap-4 justify-content-center	">
            <Button
              label="취소"
              className="p-button-danger p-button-outlined"
              onClick={() => setShowConfirmModal(false)}
            />
            <Button
              label="등록"
              className=" "
              onClick={() => {
                history.push({
                  pathname: '/compensation/accident/claim/detail/new',
                  state: { customerInfo, carDetailData },
                });
              }}
            />
          </div>
        </Modal.Default>
      )}
    </div>
  );
};

export default ApprovalList;

const TABLE_COLUMNS = [
  { field: 'carNbr', header: '차량번호' },
  { field: 'newCarNbr', header: '차량번호(변경)' },
  { field: 'vin', header: '차대번호' },
  { field: 'carName', header: '차명' },
  { field: 'customerName', header: '고객명' },
];

const STATUS_LIST = [
  { value: 'Z06', label: '입고점검완료' },
  { value: 'Z17', label: '승인대기' },
];
