import _ from 'lodash';

const PC_VALID_DAYS = 120;
const PC_BASE_BLUEPRINT = {
  INTERFACE_ID: {
    title: '인터페이스ID',
    // defaultValue: '',
    origin: '전송결과값',
  },
  CNTC_RESULT_CODE: {
    title: '결과코드',
    // defaultValue: '',
    origin: '전송결과값',
  },
  CNTC_RESULT_DTLS: {
    title: '결과내용',
    // defaultValue: '',
    origin: '전송결과값',
  },
  RCEPT_GRC_CODE: {
    title: '접수관청코드',
    // defaultValue: '',
    origin: '전송결과값',
  },
  RCEPT_DE: {
    title: '접수일',
    // defaultValue: '',
    origin: '전송결과값',
  },
  RCEPT_SN: {
    title: '접수순번',
    // defaultValue: '',
    origin: '전송결과값',
  },
  RCEPT_UPD_SN: {
    title: '경정순번',
    // defaultValue: '',
    origin: '전송결과값',
  },
  VHMNO: {
    title: '차량관리번호',
    // defaultValue: '',
    origin: '전송결과값',
  },
  CNM: {
    title: '차명',
    // defaultValue: '',
    origin: '국토부 자동차기본정보',
    originKey: 'CNM',
  },
  VHCTY_ASORT_CODE: {
    title: '차종코드',
    defaultValue: '1',
    valueItems: [
      { label: '승용', value: '1' },
      { label: '승합', value: '2' },
      { label: '화물', value: '3' },
      { label: '특수', value: '4' },
      { label: '이륜', value: '5' },
      { label: '기타', value: '9' },
    ],
  },
  REFORM_AT: {
    title: '개정여부',
    defaultValue: '2',
  },
  VHRNO: {
    title: '차량등록번호',
    // defaultValue: '',
    origin: '국토부 자동차기본정보',
    originKey: 'VHRNO',
  },
  LATEST_TRVL_DSTNC: {
    title: '최근 주행 거리',
    // defaultValue: '',
    origin: '국토부 자동차기본정보',
    originKey: 'TRVL_DSTNC',
  },
  TRVL_DSTNC: {
    title: '주행 거리',
    // defaultValue: '',
    // origin: '국토부 자동차기본정보',
    // originKey: 'TRVL_DSTNC',
  },
  TRVL_DSTNC_STTUS_CODE: {
    title: '주행거리 상태코드',
    // defaultValue: '',
    valueItems: [
      { label: '많음', value: '3' },
      { label: '보통', value: '2' },
      { label: '적음', value: '1' },
    ],
  },
  GAUGE_FNCT_AT: {
    title: '계기작동여부',
    defaultValue: '0',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  PRYE: {
    title: '연식',
    // defaultValue: '',
    origin: '국토부 자동차기본정보',
    originKey: 'PRYE',
  },
  USE_FUEL_CODE: {
    title: '사용연료코드',
    defaultValue: 'a',
    valueItems: [
      { label: '가솔린', value: 'a', name: ['휘발유(무연)'] },
      { label: '디젤', value: 'b', name: ['경유', '디젤'] },
      { label: 'LPG', value: 'c', name: ['LPG', '엘피지'] },
      { label: '전기', value: 'd', name: ['전기'] },
      { label: '수소전기', value: 'e', name: ['수소'] },
      {
        label: '하이브리드',
        value: 'x',
        name: [
          '하이브리드(휘발유+전기)',
          '하이브리드(LPG+전기)',
          '하이브리드(경유+전기)',
        ],
      },
      { label: '기타', value: 'z', name: ['기타'] },
    ],
    origin: '국토부 자동차기본정보',
    originKey: 'USE_FUEL_NM',
  },
  INSPT_VALID_PD_BGNDE: {
    title: '검사유효기간 시작일',
    // defaultValue: '',
    origin: '국토부 자동차기본정보',
    originKey: 'INSPT_VALID_PD[0]',
  },
  INSPT_VALID_PD_ENDDE: {
    title: '검사유효기간 종료일',
    // defaultValue: '',
    origin: '국토부 자동차기본정보',
    originKey: 'INSPT_VALID_PD[1]',
  },
  FRST_REGIST_DE: {
    title: '최초등록일',
    // defaultValue: '',
    origin: '국토부 자동차기본정보',
    originKey: 'FRST_REGIST_DE',
  },
  MTRS_FOM: {
    title: '원동기형식',
    // defaultValue: '',
    origin: '국토부 자동차기본정보',
    originKey: 'MTRS_FOM_NM',
  },
  GRBX_KND_CODE: {
    title: '변속기종류',
    defaultValue: '',
    valueItems: [
      { label: '자동', value: 'A' },
      { label: '수동', value: 'M' },
      { label: '세미오토', value: 'S' },
      // { label: '무단변속기', value: 'C' },
      { label: '무단', value: 'C' },
      { label: '기타', value: 'X' },
    ],
    origin: '국토부 자동차기본정보',
    originKey: 'GRBX_KND_NM',
  },
  GRBX_KND_DTLS: {
    title: '변속기종류 기타내용',
    defaultValue: '',
    origin: '국토부 자동차기본정보',
    originKey: 'GRBX_KND_CODE가 X인 경우',
  },
  VIN: {
    title: '차대번호',
    // defaultValue: '',
    origin: '국토부 자동차기본정보',
    originKey: 'VIN',
  },
  ASSRNC_TY_SE_CODE: {
    title: '보증유형',
    defaultValue: '2',
    valueItems: [
      { label: '자가 보증', value: '1' },
      { label: '보험사 보증', value: '2' },
    ],
  },
  SAMENSS_CNFIRM_CODE: {
    title: '동일성확인차대번호표기',
    defaultValue: '1',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '상이', value: '2' },
      { label: '부식', value: '3' },
      { label: '훼손(오손)', value: '4' },
      { label: '변조(변타)', value: '5' },
      { label: '도말', value: '6' },
    ],
  },
  STMD_AT: {
    title: '튜닝 여부',
    defaultValue: 'N',
    valueItems: [
      { label: '없음', value: 'N' },
      { label: '있음', value: 'Y' },
    ],
    origin: '국토부 자동차기본정보',
    originKey: 'STMD_AT',
  },
  UNLAW_STMD_AT: {
    title: '튜닝 적법 여부',
    defaultValue: '0',
    valueItems: [
      // { label: '없음', value: '0' },
      { label: '적법', value: '1' },
      { label: '불법', value: '2' },
    ],
    // origin: '국토부 자동차기본정보',
    // originKey: 'STMD_AT',
  },
  UNLAW_STMD_IEM_SE_CODE: {
    title: '튜닝 항목 구분',
    defaultValue: '0',
    valueItems: [
      // {label: '없음', value: '0'},
      { label: '구조', value: '1' },
      { label: '장치', value: '2' },
    ],
  },
  ACDNT_AT: {
    title: '사고이력',
    defaultValue: '0',
    valueItems: [
      { label: '없음', value: '0' },
      { label: '있음', value: '1' },
    ],
  },
  SIMPL_REPAIR_AT: {
    title: '단순수리여부',
    defaultValue: '0',
    valueItems: [
      { label: '없음', value: '0' },
      { label: '있음', value: '1' },
    ],
  },
  FLUD_AT: {
    title: '특별 이력',
    defaultValue: '0',
    valueItems: [
      { label: '없음', value: '0' },
      { label: '침수', value: '1' },
      { label: '화재', value: '2' },
    ],
  },
  PRPOS_CHANGE_SE_AT: {
    title: '용도 변경 여부',
    defaultValue: 'N',
    origin: '국토부 자동차기본정보',
    originKey: 'PRPOS_CHANGE_SE_AT',
    valueItems: [
      { label: '없음', value: 'N' },
      { label: '있음', value: 'Y' },
    ],
  },
  PRPOS_CHANGE_SE_CODE: {
    title: '용도 변경 구분 코드',
    defaultValue: '0',
    valueItems: [
      { label: '렌트', value: '1' },
      { label: '리스', value: '2' },
      { label: '영업용', value: '3' },
    ],
  },
  COLOR_AT: {
    title: '색상여부',
    defaultValue: '0',
    valueItems: [
      { label: '없음', value: '0' },
      { label: '무채색', value: '1' },
      { label: '유채색', value: '2' },
    ],
  },
  COLOR_CHANGE_AT: {
    title: '색상변경여부',
    defaultValue: '0',
    valueItems: [
      { label: '없음', value: '0' },
      { label: '전체도색', value: '1' },
      { label: '색상변경', value: '2' },
    ],
  },
  MAIN_OPTN_VHCLE_RF_AT: {
    title: '주요옵션_썬루프',
    defaultValue: '0',
    valueItems: [
      { label: '없음', value: '0' },
      { label: '있음', value: '1' },
    ],
  },
  MAIN_OPTN_ROAD_GUIDANCE_AT: {
    title: '주요옵션_네비게이션',
    defaultValue: '0',
    valueItems: [
      { label: '없음', value: '0' },
      { label: '있음', value: '1' },
    ],
  },
  MAIN_OPTN_ETC_AT: {
    title: '주요옵션_기타',
    defaultValue: '0',
    valueItems: [
      { label: '없음', value: '0' },
      { label: '있음', value: '1' },
    ],
  },
  RECALL_TRGET_AT: {
    title: '리콜대상 여부',
    defaultValue: '0',
    valueItems: [
      { label: '해당없음', value: '0' },
      { label: '있음', value: '1' },
    ],
  },
  RECALL_FLFL_AT: {
    title: '리콜이행 여부',
    defaultValue: '0',
    valueItems: [
      { label: '미이행', value: '0' },
      { label: '이행', value: '1' },
    ],
  },
  ENGINE_STBLT_AT: {
    title: '자기진단사항_원동기',
    defaultValue: '1',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '2' },
    ],
  },
  GRBX_STBLT_AT: {
    title: '자기진단사항_변속기',
    defaultValue: '1',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '2' },
    ],
  },

  SMOKE_MESURE_VALUE: {
    title: '배출가스 매연측정값',
    defaultValue: 0,
  },
  CRMN_MESURE_VALUE: {
    title: '배출가스 일산화탄소 측정값',
    defaultValue: 0.0,
  },
  HYDR_MESURE_VALUE: {
    title: '배출가스 탄화수소 측정값',
    defaultValue: 0,
  },
  ETC_MATTER: {
    title: '특이사항 및 점검자의견',
    defaultValue:
      '비금속(FRP, 플라스틱)의 탈부착 가능 부품은 점검사항에서 제외되며 중고차 특성 상 부분적인 판금, 도색, 차량의 노후화에 따른 자연스러운 부식이 있을 수 있습니다.',
  },
  CHCK_ENTRPS_BIZ_NO: {
    title: '점검업체 사업자등록번호',
    defaultValue: '',
  },
  CHCK_IMPRMN_ENTRPS_ID: {
    title: '점검정비업체번호',
    defaultValue: '',
  },
  CHCK_ENTRPS_NM: {
    title: '점검법인명',
    defaultValue: '',
  },
  INSCTR_NM: {
    title: '점검자명',
    defaultValue: '',
  },
  CHCK_DE: {
    title: '점검일',
    defaultValue: '',
  },
  NTIC_ENTRPS_ID: {
    title: '고지업체(매매업체)번호',
    defaultValue: '',
  },
  NTIC_ENTRPS_NM: {
    title: '고지업체명',
    defaultValue: '',
  },
  NTIC_PERSON_NM: {
    title: '고지사람명',
    defaultValue: '',
  },
  PC_EXAMIN_CALC_ENTRPS_NM: {
    title: '가격조사산정업체명',
    defaultValue: '',
  },
  PC_EXAMIN_CALC_CHARGER_NM: {
    title: '가격조사산정담당자명',
    defaultValue: '',
  },
  PC_EXAMIN_CALC_DE: {
    title: '가격조사산정일',
    defaultValue: '',
  },
  SPCABL_MATTER_ND_CALC_RESN: {
    title: '특이사항 및 산정자의견',
    defaultValue: '',
  },
  PC_CALC_STDR_ASOC_CODE: {
    title: '가격산정기준협회코드',
    defaultValue: '',
    valueItems: [
      { label: '기술사회', value: '0' },
      { label: '한국자동차진단보증협회', value: '1' },
    ],
  },
  ICNY_CODE: {
    title: '보험사코드',
    defaultValue: '',
  },
  PRFOMNC_CHCK_NO: {
    title: '성능점검번호',
    defaultValue: '',
  },
  EXTRR_REPAIR_NEED_AT: {
    title: '외장수리필요여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  ITRDECO_REPAIR_NEED_AT: {
    title: '내장수리필요여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  METAL_SURFC_CLNSG_NEED_AT: {
    title: '광택수리필요여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  ENGINE_ROOM_CLN_REPAIR_NEED_AT: {
    title: '룸크리닝수리필요여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  RIM_DRV_SEAT_FRNT_REPAIR_AT: {
    title: '휠_운전좌석_전_수리여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  RIM_DRV_SEAT_AFTR_REPAIR_AT: {
    title: '휠_운전좌석_후_수리여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  RIM_ACPSEAT_FRNT_REPAIR_AT: {
    title: '휠_동반좌석_전_수리여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  RIM_ACPSEAT_AFTR_REPAIR_AT: {
    title: '휠_동반좌석_후_수리여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  RIM_EMGNCY_REPAIR_NEED_AT: {
    title: '휠_응급수리필요여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  TIRE_DRV_SEAT_FRNT_REPAIR_AT: {
    title: '타이어_운전좌석_전_수리여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  TIRE_DRV_SEAT_AFTR_REPAIR_AT: {
    title: '타이어_운전좌석_후_수리여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  TIRE_ACPSEAT_FRNT_REPAIR_AT: {
    title: '타이어_동반좌석_전_수리여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  TIRE_ACPSEAT_AFTR_REPAIR_AT: {
    title: '타이어_동반좌석_후_수리여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  TIRE_EMGNCY_REPAIR_NEED_AT: {
    title: '타이어_응급수리필요여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  GLASS_REPAIR_NEED_AT: {
    title: '유리수리필요여부',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '0' },
      { label: '불량', value: '1' },
    ],
  },
  BASS_PRDLST_HOLD_USE_MNL_AT: {
    title: '기본품목보유_사용설명서여부',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '0' },
      { label: '있음', value: '1' },
    ],
  },
  BASS_PRDLST_HOLD_TH_BRAKT_AT: {
    title: '기본품목보유_안전삼각대여부',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '0' },
      { label: '있음', value: '1' },
    ],
  },
  BASS_PRDLST_HOLD_TOOL_AT: {
    title: '기본품목보유_잭여부',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '0' },
      { label: '있음', value: '1' },
    ],
  },
  BASS_PRDLST_HOLD_TQWRNCH_AT: {
    title: '기본품목보유_스패너여부',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '0' },
      { label: '있음', value: '1' },
    ],
  },
  BUYER_DE: {
    title: '매수일',
    defaultValue: '',
  },
  BUYER_NM: {
    title: '매수자명',
    defaultValue: '',
  },
  PC_CALC_CHOISE_AT: {
    title: '가격산정선택여부',
    defaultValue: '0',
    valueItems: [
      { label: '선택안함', value: '0' },
      { label: '선택', value: '1' },
    ],
  },
  UPD_CODE: {
    title: '경정구분',
    defaultValue: 'N',
    valueItems: [
      { label: '신규 등록', value: 'N' },
      { label: '기존건 수정 경정', value: 'U' },
      { label: '기존건 삭제 경정', value: 'D' },
    ],
  },
  UPD_REASON: {
    title: '경정사유',
    defaultValue: '',
  },
  AGENT_CODE: {
    title: '연계기관코드',
    defaultValue: '',
  },
};

const PC_STATUS_BLUEPRINT = {
  s001: {
    title: '원동기 - 작동상태(공회전)',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '5' },
    ],
  },
  s057: {
    title: '실린더 커버(로커암 커버)',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '1' },
      { label: '미세누유', value: '2' },
      { label: '누유', value: '3' },
    ],
  },
  s003: {
    title: '실린더 헤드 / 개스킷',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '1' },
      { label: '미세누유', value: '2' },
      { label: '누유', value: '3' },
    ],
  },
  s058: {
    title: '실린더 블록 / 오일팬',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '1' },
      { label: '미세누유', value: '2' },
      { label: '누유', value: '3' },
    ],
  },
  s005: {
    title: '오일 유량',
    defaultValue: '',
    valueItems: [
      { label: '적정', value: '1' },
      { label: '부족', value: '2' },
    ],
  },
  s007: {
    title: '실린더 헤드 / 개스킷',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '1' },
      { label: '미세누수', value: '2' },
      { label: '누수', value: '3' },
    ],
  },
  s008: {
    title: '워터펌프',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '1' },
      { label: '미세누수', value: '2' },
      { label: '누수', value: '3' },
    ],
  },
  s009: {
    title: '라디에이터',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '1' },
      { label: '미세누수', value: '2' },
      { label: '누수', value: '3' },
    ],
  },
  s010: {
    title: '냉각수 수량',
    defaultValue: '',
    valueItems: [
      { label: '적정', value: '1' },
      { label: '부족', value: '2' },
    ],
  },
  s011: {
    title: '커먼레일',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '3' },
    ],
  },
  s012: {
    title: '오일누유',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '1' },
      { label: '미세누유', value: '2' },
      { label: '누유', value: '3' },
    ],
  },
  s013: {
    title: '오일유량 및 상태',
    defaultValue: '',
    valueItems: [
      { label: '적정', value: '1' },
      { label: '부족', value: '2' },
      { label: '과다', value: '3' },
    ],
  },
  s016: {
    title: '작동상태(공회전)',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '6' },
    ],
  },
  s017: {
    title: '오일누유',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '1' },
      { label: '미세누유', value: '2' },
      { label: '누유', value: '3' },
    ],
  },
  s018: {
    title: '기어변속장치',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '5' },
    ],
  },
  s019: {
    title: '오일유량 및 상태',
    defaultValue: '',
    valueItems: [
      { label: '적정', value: '1' },
      { label: '부족', value: '2' },
      { label: '과다', value: '3' },
    ],
  },
  s020: {
    title: '작동상태(공회전)',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '6' },
    ],
  },
  s021: {
    title: '클러치 어셈블리',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '7' },
    ],
  },
  s022: {
    title: '등속조인트',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '4' },
    ],
  },
  s023: {
    title: '추친축 및 베어링',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '5' },
    ],
  },
  s060: {
    title: '디퍼렌셜 기어',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '5' },
    ],
  },
  s024: {
    title: '동력조향 작동 오일 누유',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '1' },
      { label: '미세누유', value: '2' },
      { label: '누유', value: '3' },
    ],
  },
  s025: {
    title: '스티어링기어 (MDPS포함)',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '5' },
    ],
  },
  s026: {
    title: '스티어링 펌프',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '4' },
    ],
  },
  s061: {
    title: '스티어링조인트',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '5' },
    ],
  },
  s062: {
    title: '파워고압호스',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '5' },
    ],
  },
  s027: {
    title: '타이로드엔드 및 볼 조인트',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '3' },
    ],
  },
  s059: {
    title: '브레이크 마스터 실린더 오일 누유',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '1' },
      { label: '미세누유', value: '2' },
      { label: '누유', value: '3' },
    ],
  },
  s029: {
    title: '브레이크 오일 누유',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '1' },
      { label: '누유', value: '3' },
      { label: '미세누유', value: '4' },
    ],
  },
  s030: {
    title: '배력장치 상태',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '3' },
    ],
  },
  s031: {
    title: '발전기 출력',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '3' },
    ],
  },
  s055: {
    title: '시동 모터',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '3' },
    ],
  },
  s032: {
    title: '와이퍼 모터 기능',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '3' },
    ],
  },
  s033: {
    title: '실내송풍 모터',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '4' },
    ],
  },
  s034: {
    title: '라디에이터 팬 모터',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '4' },
    ],
  },
  s036: {
    title: '윈도우 모터',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '4' },
    ],
  },
  s063: {
    title: '충전구 절연 상태',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '5' },
    ],
  },
  s064: {
    title: '구동축전지 격리 상태',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '5' },
    ],
  },
  s065: {
    title: '고전원전기배선 상태 (접속단자, 피복, 보호기구)',
    defaultValue: '',
    valueItems: [
      { label: '양호', value: '1' },
      { label: '불량', value: '5' },
    ],
  },
  s035: {
    title: '연료누출 (LP 가스 포함)',
    defaultValue: '',
    valueItems: [
      { label: '없음', value: '1' },
      { label: '있음', value: '2' },
    ],
  },
  s037: {
    title: '후드',
    defaultValue: '1',
  },
  s038: {
    title: '프론트펜더',
    defaultValue: '1',
  },
  s039: {
    title: '도어',
    defaultValue: '1',
  },
  s040: {
    title: '트렁크리드',
    defaultValue: '1',
  },
  s041: {
    title: '라디에이터 서포트(볼트체결부품)',
    defaultValue: '1',
  },
  s042: {
    title: '루프패널',
    defaultValue: '1',
  },
  s043: {
    title: '쿼터패널(리얼펜더)',
    defaultValue: '1',
  },
  s044: {
    title: '사이드실패널',
    defaultValue: '1',
  },
  s045: {
    defaultValue: '1',
    title: '프론트패널',
  },
  s046: {
    defaultValue: '1',
    title: '크로스멤버',
  },
  s047: {
    defaultValue: '1',
    title: '인사이드패널',
  },
  s048: {
    defaultValue: '1',
    title: '사이드멤버',
  },
  s049: {
    defaultValue: '1',
    title: '휠하우스',
  },
  s050: {
    defaultValue: '1',
    title: '대쉬패널',
  },
  s051: {
    defaultValue: '1',
    title: '플로어패널',
  },
  s052: {
    defaultValue: '1',
    title: '필러패널',
  },
  s053: {
    defaultValue: '1',
    title: '리어패널',
  },
  s054: {
    defaultValue: '1',
    title: '트렁크플로어',
  },
  s056: {
    defaultValue: '1',
    title: '패키지트레이',
  },
};

const PARTS_LEFT = [
  {
    partPoint: 'pp_22',
    partNumber: '2',
    partSubNumber: '',
    dataKey: 's038',
    title: '프론트펜더(좌)',
    pos: [40, 80],
    rpos: [40 - 18, 80 - 23],
  },
  {
    partPoint: 'pp_63',
    partNumber: '14',
    partSubNumber: 'A',
    dataKey: 's052',
    title: '필러패널(A)',
    pos: [120, 120],
    rpos: [120 - 45, 120 - 40],
  },
  {
    partPoint: 'pp_81',
    partNumber: '8',
    partSubNumber: '',
    dataKey: 's044',
    title: '사이드실패널',
    pos: [170, 30],
    rpos: [170 - 60, 30 - 5.5],
  },
  {
    partPoint: 'pp_82',
    partNumber: '3',
    partSubNumber: '',
    dataKey: 's039',
    title: '도어(좌측전방)',
    pos: [160, 80],
    rpos: [160 - 58, 80 - 23],
  },
  {
    partPoint: 'pp_102',
    partNumber: '3',
    partSubNumber: '',
    dataKey: 's039',
    title: '도어(좌측후방)',
    pos: [234, 80],
    rpos: [234 - 82, 80 - 25],
  },
  {
    partPoint: 'pp_103',
    partNumber: '14',
    partSubNumber: 'B',
    dataKey: 's052',
    title: '필러패널(B)',
    pos: [205, 135],
    rpos: [205 - 72, 135 - 46],
  },
  {
    partPoint: 'pp_144',
    partNumber: '14',
    partSubNumber: 'C',
    dataKey: 's052',
    title: '필러패널(C)',
    pos: [300, 130],
    rpos: [300 - 105, 130 - 40],
  },
  {
    partPoint: 'pp_162',
    partNumber: '6',
    partSubNumber: '',
    dataKey: 's043',
    title: '쿼터패널(좌측리어펜더)',
    pos: [337.5, 80],
    rpos: [337.5 - 115, 80 - 20],
  },
];

const PARTS_RIGHT = [
  {
    partPoint: 'pp_39',
    partNumber: '2',
    partSubNumber: '',
    dataKey: 's038',
    title: '프론트펜더(우)',
    pos: [46, 105],
    rpos: [46 - 18, 105 - 32],
  },
  {
    partPoint: 'pp_78',
    partNumber: '14',
    partSubNumber: 'A',
    dataKey: 's052',
    title: '필러패널(A)',
    pos: [120, 80],
    rpos: [120 - 45, 80 - 28],
  },
  {
    partPoint: 'pp_100',
    partNumber: '8',
    partSubNumber: '',
    dataKey: 's044',
    title: '사이드실패널',
    pos: [166, 160],
    rpos: [166 - 55, 160 - 50],
  },
  {
    partPoint: 'pp_99',
    partNumber: '3',
    partSubNumber: '',
    dataKey: 's039',
    title: '도어(우측전방)',
    pos: [155, 100],
    rpos: [155 - 52, 100 - 28],
  },
  {
    partPoint: 'pp_139',
    partNumber: '3',
    partSubNumber: '',
    dataKey: 's039',
    title: '도어(우측후방)',
    pos: [235, 100],
    rpos: [235 - 83, 100 - 28],
  },
  {
    partPoint: 'pp_118',
    partNumber: '14',
    partSubNumber: 'B',
    dataKey: 's052',
    title: '필러패널(B)',
    pos: [205, 55],
    rpos: [205 - 70, 55 - 20],
  },
  {
    partPoint: 'pp_137',
    partNumber: '14',
    partSubNumber: 'C',
    dataKey: 's052',
    title: '필러패널(C)',
    pos: [300, 60],
    rpos: [300 - 105, 60 - 20],
  },
  {
    partPoint: 'pp_179',
    partNumber: '6',
    partSubNumber: '',
    dataKey: 's043',
    title: '쿼터패널(우측리어펜더)',
    pos: [339, 110],
    rpos: [332 - 114, 153 - 82],
  },
];

const PARTS_TOP = [
  {
    partPoint: 'pp_28',
    partNumber: '1',
    partSubNumber: '',
    dataKey: 's037',
    title: '후드',
    pos: [50, 140],
    rpos: [50 - 17, 140 - 55],
  },
  {
    partPoint: 'pp_107',
    partNumber: '7',
    partSubNumber: '',
    dataKey: 's042',
    title: '루프패널',
    pos: [200, 140],
    rpos: [200 - 70, 140 - 55],
  },
  {
    partPoint: 'pp_168',
    partNumber: '4',
    partSubNumber: '',
    dataKey: 's040',
    title: '트렁크리드',
    pos: [330, 140],
    rpos: [330 - 116, 140 - 55],
  },
];

const PARTS_BOTTOM = [
  {
    partPoint: 'pp_14',
    partNumber: '5',
    partSubNumber: '',
    dataKey: 's041',
    title: '라디에이터서포트(볼트체결부품)',
    pos: [7, 100],
    rpos: [7 - 4.5, 166 - 97],
  },
  {
    partPoint: 'pp_33',
    partNumber: '9',
    partSubNumber: '',
    dataKey: 's045',
    title: '프론트패널',
    pos: [34, 100],
    rpos: [34 - 20.5, 100 - 31],
  },
  {
    partPoint: 'pp_32',
    partNumber: '11',
    partSubNumber: '',
    dataKey: 's047',
    title: '인사이드패널(좌)',
    pos: [46, 35],
    rpos: [46 - 8.5, 84 - 56],
  },
  {
    partPoint: 'pp_34',
    partNumber: '11',
    partSubNumber: '',
    dataKey: 's047',
    title: '인사이드패널(우)',
    pos: [46, 160],
    rpos: [46 - 6.5, 160 - 50],
  },
  {
    partPoint: 'pp_53',
    partNumber: '12',
    partSubNumber: '',
    dataKey: 's048',
    title: '사이드멤버(좌측전방)',
    pos: [72, 62],
    rpos: [72 - 16.5, 62 - 15],
  },
  {
    partPoint: 'pp_54',
    partNumber: '12',
    partSubNumber: '',
    dataKey: 's048',
    title: '사이드멤버(우측전방)',
    pos: [72, 132],
    rpos: [72 - 16.5, 132 - 40],
  },
  {
    partPoint: 'pp_52',
    partNumber: '13',
    partSubNumber: '',
    dataKey: 's049',
    title: '휠하우스(좌측전방)',
    pos: [98, 35],
    rpos: [98 - 36, 35 - 8],
  },
  {
    partPoint: 'pp_55',
    partNumber: '13',
    partSubNumber: '',
    dataKey: 's049',
    title: '휠하우스(우측전방)',
    pos: [98, 160],
    rpos: [98 - 36, 160 - 50],
  },
  {
    partPoint: 'pp_73',
    partNumber: '10',
    partSubNumber: '',
    dataKey: 's046',
    title: '크로스멤버',
    pos: [115, 100],
    rpos: [115 - 37.5, 100 - 30],
  },
  {
    partPoint: 'pp_74',
    partNumber: '15',
    partSubNumber: '',
    dataKey: 's050',
    title: '대쉬패널',
    pos: [144, 100],
    rpos: [144 - 55, 100 - 30],
  },
  {
    partPoint: 'pp_94',
    partNumber: '16',
    partSubNumber: '',
    dataKey: 's051',
    title: '플로어패널',
    pos: [182, 100],
    rpos: [182 - 62, 100 - 30],
  },
  {
    partPoint: 'pp_153',
    partNumber: '19',
    partSubNumber: '',
    dataKey: 's056',
    title: '패키지트레이',
    pos: [286, 100],
    rpos: [286 - 98, 100 - 30],
  },
  {
    partPoint: 'pp_152',
    partNumber: '13',
    partSubNumber: '',
    dataKey: 's049',
    title: '휠하우스(좌측후방)',
    pos: [295, 35],
    rpos: [295 - 102, 35 - 10],
  },
  {
    partPoint: 'pp_154',
    partNumber: '13',
    partSubNumber: '',
    dataKey: 's049',
    title: '휠하우스(우측후방)',
    pos: [295, 160],
    rpos: [295 - 102, 160 - 50],
  },
  {
    partPoint: 'pp_172',
    partNumber: '12',
    partSubNumber: '',
    dataKey: 's048',
    title: '사이드멤버(좌측후방)',
    pos: [336, 35],
    rpos: [336 - 116, 35 - 5],
  },
  {
    partPoint: 'pp_174',
    partNumber: '12',
    partSubNumber: '',
    dataKey: 's048',
    title: '사이드멤버(우측후방)',
    pos: [336, 160],
    rpos: [336 - 116, 160 - 50],
  },
  {
    partPoint: 'pp_173',
    partNumber: '17',
    partSubNumber: '',
    dataKey: 's054',
    title: '트렁크플로어',
    pos: [335, 100],
    rpos: [335 - 114, 100 - 30],
  },
  {
    partPoint: 'pp_194',
    partNumber: '18',
    partSubNumber: '',
    dataKey: 's053',
    title: '리어패널',
    pos: [372, 100],
    rpos: [372 - 128, 100 - 30],
  },
];

const PARTS_TOP_M = [
  {
    partPoint: 'pp_28',
    partNumber: '1',
    partSubNumber: '',
    dataKey: 's037',
    title: '후드',
    pos: [25, 70],
    rpos: [25 - 8, 70 - 27],
  },
  {
    partPoint: 'pp_107',
    partNumber: '7',
    partSubNumber: '',
    dataKey: 's042',
    title: '루프패널',
    pos: [100, 70],
    rpos: [100 - 35, 70 - 27],
  },
  {
    partPoint: 'pp_168',
    partNumber: '4',
    partSubNumber: '',
    dataKey: 's040',
    title: '트렁크리드',
    pos: [165, 70],
    rpos: [165 - 58, 70 - 27],
  },
];

const PARTS_LEFT_M = [
  {
    partPoint: 'pp_22',
    partNumber: '2',
    partSubNumber: '',
    dataKey: 's038',
    title: '프론트펜더(좌)',
    pos: [20, 40],
    rpos: [11, 28.5],
  },
  {
    partPoint: 'pp_63',
    partNumber: '14',
    partSubNumber: 'A',
    dataKey: 's052',
    title: '필러패널(A)',
    pos: [60, 60],
    rpos: [37.5, 40],
  },
  {
    partPoint: 'pp_81',
    partNumber: '8',
    partSubNumber: '',
    dataKey: 's044',
    title: '사이드실패널',
    pos: [85, 15],
    rpos: [55, 2.75],
  },
  {
    partPoint: 'pp_82',
    partNumber: '3',
    partSubNumber: '',
    dataKey: 's039',
    title: '도어(좌측전방)',
    pos: [80, 40],
    rpos: [51, 28.5],
  },
  {
    partPoint: 'pp_102',
    partNumber: '3',
    partSubNumber: '',
    dataKey: 's039',
    title: '도어(좌측후방)',
    pos: [117, 40],
    rpos: [76, 27.5],
  },
  {
    partPoint: 'pp_103',
    partNumber: '14',
    partSubNumber: 'B',
    dataKey: 's052',
    title: '필러패널(B)',
    pos: [102.5, 67.5],
    rpos: [66.5, 44.5],
  },
  {
    partPoint: 'pp_144',
    partNumber: '14',
    partSubNumber: 'C',
    dataKey: 's052',
    title: '필러패널(C)',
    pos: [150, 65],
    rpos: [97.5, 45],
  },
  {
    partPoint: 'pp_162',
    partNumber: '6',
    partSubNumber: '',
    dataKey: 's043',
    title: '쿼터패널(좌측리어펜더)',
    pos: [168.75, 40],
    rpos: [111.25, 30],
  },
];

const PARTS_RIGHT_M = [
  {
    partPoint: 'pp_39',
    partNumber: '2',
    partSubNumber: '',
    dataKey: 's038',
    title: '프론트펜더(우)',
    pos: [23, 52.5],
    rpos: [14, 36.5],
  },
  {
    partPoint: 'pp_78',
    partNumber: '14',
    partSubNumber: 'A',
    dataKey: 's052',
    title: '필러패널(A)',
    pos: [60, 40],
    rpos: [37.5, 26],
  },
  {
    partPoint: 'pp_100',
    partNumber: '8',
    partSubNumber: '',
    dataKey: 's044',
    title: '사이드실패널',
    pos: [83, 80],
    rpos: [55.5, 55],
  },
  {
    partPoint: 'pp_99',
    partNumber: '3',
    partSubNumber: '',
    dataKey: 's039',
    title: '도어(우측전방)',
    pos: [77.5, 50],
    rpos: [51.5, 36],
  },
  {
    partPoint: 'pp_139',
    partNumber: '3',
    partSubNumber: '',
    dataKey: 's039',
    title: '도어(우측후방)',
    pos: [117.5, 50],
    rpos: [76, 36],
  },
  {
    partPoint: 'pp_118',
    partNumber: '14',
    partSubNumber: 'B',
    dataKey: 's052',
    title: '필러패널(B)',
    pos: [102.5, 27.5],
    rpos: [67.5, 17.5],
  },
  {
    partPoint: 'pp_137',
    partNumber: '14',
    partSubNumber: 'C',
    dataKey: 's052',
    title: '필러패널(C)',
    pos: [150, 30],
    rpos: [97.5, 20],
  },
  {
    partPoint: 'pp_179',
    partNumber: '6',
    partSubNumber: '',
    dataKey: 's043',
    title: '쿼터패널(우측리어펜더)',
    pos: [169.5, 55],
    rpos: [109, 35.5],
  },
];

const PARTS_BOTTOM_M = [
  {
    partPoint: 'pp_14',
    partNumber: '5',
    partSubNumber: '',
    dataKey: 's041',
    title: '라디에이터서포트(볼트체결부품)',
    pos: [3.5, 50],
    rpos: [1.25, 34.5],
  },
  {
    partPoint: 'pp_33',
    partNumber: '9',
    partSubNumber: '',
    dataKey: 's045',
    title: '프론트패널',
    pos: [17, 50],
    rpos: [6.75, 34.5],
  },
  {
    partPoint: 'pp_32',
    partNumber: '11',
    partSubNumber: '',
    dataKey: 's047',
    title: '인사이드패널(좌)',
    pos: [23, 17.5],
    rpos: [18.75, 14],
  },
  {
    partPoint: 'pp_34',
    partNumber: '11',
    partSubNumber: '',
    dataKey: 's047',
    title: '인사이드패널(우)',
    pos: [23, 80],
    rpos: [19.75, 55],
  },
  {
    partPoint: 'pp_53',
    partNumber: '12',
    partSubNumber: '',
    dataKey: 's048',
    title: '사이드멤버(좌측전방)',
    pos: [36, 31],
    rpos: [27.75, 23.5],
  },
  {
    partPoint: 'pp_54',
    partNumber: '12',
    partSubNumber: '',
    dataKey: 's048',
    title: '사이드멤버(우측전방)',
    pos: [36, 66],
    rpos: [27.75, 46],
  },
  {
    partPoint: 'pp_52',
    partNumber: '13',
    partSubNumber: '',
    dataKey: 's049',
    title: '휠하우스(좌측전방)',
    pos: [49, 17.5],
    rpos: [31, 13.5],
  },
  {
    partPoint: 'pp_55',
    partNumber: '13',
    partSubNumber: '',
    dataKey: 's049',
    title: '휠하우스(우측전방)',
    pos: [49, 80],
    rpos: [31, 55],
  },
  {
    partPoint: 'pp_73',
    partNumber: '10',
    partSubNumber: '',
    dataKey: 's046',
    title: '크로스멤버',
    pos: [57.5, 50],
    rpos: [38.75, 35],
  },
  {
    partPoint: 'pp_74',
    partNumber: '15',
    partSubNumber: '',
    dataKey: 's050',
    title: '대쉬패널',
    pos: [72, 50],
    rpos: [44.5, 35],
  },
  {
    partPoint: 'pp_94',
    partNumber: '16',
    partSubNumber: '',
    dataKey: 's051',
    title: '플로어패널',
    pos: [91, 50],
    rpos: [60, 35],
  },
  {
    partPoint: 'pp_153',
    partNumber: '19',
    partSubNumber: '',
    dataKey: 's056',
    title: '패키지트레이',
    pos: [143, 50],
    rpos: [94, 35],
  },
  {
    partPoint: 'pp_152',
    partNumber: '13',
    partSubNumber: '',
    dataKey: 's049',
    title: '휠하우스(좌측후방)',
    pos: [147.5, 17.5],
    rpos: [96.5, 12.5],
  },
  {
    partPoint: 'pp_154',
    partNumber: '13',
    partSubNumber: '',
    dataKey: 's049',
    title: '휠하우스(우측후방)',
    pos: [147.5, 80],
    rpos: [96.5, 55],
  },
  {
    partPoint: 'pp_172',
    partNumber: '12',
    partSubNumber: '',
    dataKey: 's048',
    title: '사이드멤버(좌측후방)',
    pos: [168, 17.5],
    rpos: [110, 15],
  },
  {
    partPoint: 'pp_174',
    partNumber: '12',
    partSubNumber: '',
    dataKey: 's048',
    title: '사이드멤버(우측후방)',
    pos: [168, 80],
    rpos: [110, 55],
  },
  {
    partPoint: 'pp_173',
    partNumber: '17',
    partSubNumber: '',
    dataKey: 's054',
    title: '트렁크플로어',
    pos: [167.5, 50],
    rpos: [110.5, 35],
  },
  {
    partPoint: 'pp_194',
    partNumber: '18',
    partSubNumber: '',
    dataKey: 's053',
    title: '리어패널',
    pos: [186, 50],
    rpos: [122, 35],
  },
];

const PARTS_ALL = _.concat(PARTS_LEFT, PARTS_RIGHT, PARTS_TOP, PARTS_BOTTOM);

const ACCIDENT_STATUS = {
  pp_162: ['2'], // 6.쿼터패널(좌)
  pp_179: ['2'], // 6.쿼터패널(우)
  pp_107: ['2'], // 7.루프패널
  pp_81: ['2'], // 8.사이드실패널(우)
  pp_100: ['2'], // 8.사이드실패널(좌)
  pp_33: ['2', '3'], // 9.프론트패널
  pp_73: ['2', '3'], // 10.크로스멤버
  pp_32: ['2', '3'], // 11.인사이드패널(좌)
  pp_34: ['2', '3'], // 11.인사이드패널(우)
  pp_53: ['2', '3'], // 12.사이드멤버(좌전)
  pp_54: ['2', '3'], // 12.사이드멤버(우전)
  pp_172: ['2', '3'], // 12.사이드멤버(좌후)
  pp_174: ['2', '3'], // 12.사이드멤버(우후)
  pp_52: ['2', '3'], // 13.휠하우스(좌전)
  pp_55: ['2', '3'], // 13.휠하우스(우전)
  pp_152: ['2', '3'], // 13.휠하우스(좌후)
  pp_154: ['2', '3'], // 13.휠하우스(우후)
  pp_63: ['2', '3'], // 14.A필러(좌)
  pp_103: ['2', '3'], // 14.B필러(좌)
  pp_144: ['2', '3'], // 14.C필러(좌)
  pp_78: ['2', '3'], // 14.A필러(우)
  pp_118: ['2', '3'], // 14.B필러(우)
  pp_137: ['2', '3'], // 14.C필러(우)
  pp_74: ['2', '3'], // 15.대쉬패널
  pp_94: ['2', '3'], // 16.플로어패널
  pp_173: ['2', '3'], // 17.트렁크플로어
  pp_194: ['2', '3'], // 18.리어패널
  pp_153: ['2', '3'], // 19.패키지트레이
};

const ACCIDENT_STATUS_TITLE = {
  pp_162: '쿼터패널(좌)',
  pp_179: '쿼터패널(우)',
  pp_107: '루프패널',
  pp_81: '사이드실패널(우)',
  pp_100: '사이드실패널(좌)',
  pp_33: '프론트패널',
  pp_73: '크로스멤버',
  pp_32: '인사이드패널(좌)',
  pp_34: '인사이드패널(우)',
  pp_53: '사이드멤버(좌전)',
  pp_54: '사이드멤버(우전)',
  pp_172: '사이드멤버(좌후)',
  pp_174: '사이드멤버(우후)',
  pp_52: '휠하우스(좌전)',
  pp_55: '휠하우스(우전)',
  pp_152: '휠하우스(좌후)',
  pp_154: '휠하우스(우후)',
  pp_63: '필러(좌)',
  pp_103: '필러(좌)',
  pp_144: '필러(좌)',
  pp_78: '필러(우)',
  pp_118: '필러(우)',
  pp_137: '필러(우)',
  pp_74: '대쉬패널',
  pp_94: '플로어패널',
  pp_173: '트렁크플로어',
  pp_194: '리어패널',
  pp_153: '패키지트레이',
};

const REPAIR_STATUS = {
  pp_28: ['2', '3'], // 1.후드
  pp_22: ['2', '3'], // 2.프론트휀더(좌)
  pp_39: ['2', '3'], // 2.프론트휀더(우)
  pp_82: ['2', '3'], // 3.도어(좌측전방)
  pp_102: ['2', '3'], // 3.도어(좌측후방)
  pp_99: ['2', '3'], // 3.도어(우측전방)
  pp_139: ['2', '3'], // 3.도어(우측후방)
  pp_168: ['2', '3'], // 4.트렁크리드
  pp_14: ['2', '3'], // 5.라디에이터서포트
  pp_162: ['3'], // 6.쿼터패널(좌)
  pp_179: ['3'], // 6.쿼터패널(우)
  pp_107: ['3'], // 7.루프패널
  pp_81: ['3'], // 8.사이드실패널(좌)
  pp_100: ['3'], // 8.사이드실패널(우)
};

const REPAIR_STATUS_TITLE = {
  pp_28: '후드', // 1.후드
  pp_22: '프론트휀더(좌)', // 2.프론트휀더(좌)
  pp_39: '프론트휀더(우)', // 2.프론트휀더(우)
  pp_82: '도어(좌측전방)', // 3.도어(좌측전방)
  pp_102: '도어(좌측후방)', // 3.도어(좌측후방)
  pp_99: '도어(우측전방)', // 3.도어(우측전방)
  pp_139: '도어(우측후방)', // 3.도어(우측후방)
  pp_168: '트렁크리드', // 4.트렁크리드
  pp_14: '라디에이터서포트', // 5.라디에이터서포트
  pp_162: '쿼터패널(좌)', // 6.쿼터패널(좌)
  pp_179: '쿼터패널(우)', // 6.쿼터패널(우)
  pp_107: '루프패널', // 7.루프패널
  pp_81: '사이드실패널(좌)', // 8.사이드실패널(좌)
  pp_100: '사이드실패널(우)', // 8.사이드실패널(우)
};

const MAIN_FRAMES = [
  { key: '1', dataKey: 's037' }, // 1.후드
  { key: '2', dataKey: 's038' }, // 2.프론트휀더
  { key: '3', dataKey: 's039' }, // 3.도어
  { key: '4', dataKey: 's040' }, // 4.트렁크 리드
  { key: '5', dataKey: 's041' }, // 5.라디에이터서포트(볼트체결부품)
  { key: '6', dataKey: 's043' }, // 6.쿼터패널
  { key: '7', dataKey: 's042' }, // 7.루프패널
  { key: '8', dataKey: 's044' }, // 8.사이드실패널
  { key: '9', dataKey: 's045' }, // 9.프론트패널
  { key: '10', dataKey: 's046' }, // 10.크로스멤버
  { key: '11', dataKey: 's047' }, // 11.인사이드패널
  { key: '12', dataKey: 's048' }, // 12.사이드멤버
  { key: '13', dataKey: 's049' }, // 13.휠하우스
  { key: '14', dataKey: 's052' }, // 14.필러패널
  { key: '14A', dataKey: 'D14A' }, // 14.필러패널 A
  { key: '14B', dataKey: 'D14B' }, // 14.필러패널 B
  { key: '14C', dataKey: 'D14C' }, // 14.필러패널 C
  { key: '15', dataKey: 's050' }, // 15.대쉬패널
  { key: '16', dataKey: 's051' }, // 16.플로워패널
  { key: '17', dataKey: 's054' }, // 17.트렁크플로워
  { key: '18', dataKey: 's053' }, // 18.리어패널
  { key: '19', dataKey: 's056' }, // 19.패키지트레이
];

const GASOLINE_PARTS = [
  's001',
  's057',
  's003',
  's058',
  's005',
  's007',
  's008',
  's009',
  's010',
  's022',
  's023',
  's060',
  's024',
  's026',
  's025',
  's061',
  's062',
  's027',
  's059',
  's029',
  's030',
  's031',
  's055',
  's032',
  's033',
  's034',
  's035',
  's036',
];

const DIESEL_PARTS = [
  's001',
  's057',
  's003',
  's058',
  's005',
  's007',
  's008',
  's009',
  's010',
  's011',
  's016',
  's022',
  's023',
  's060',
  's024',
  's026',
  's025',
  's061',
  's062',
  's027',
  's059',
  's029',
  's030',
  's031',
  's055',
  's032',
  's033',
  's034',
  's036',
  's035',
];

const LPG_PARTS = [
  's001',
  's057',
  's003',
  's058',
  's005',
  's007',
  's008',
  's009',
  's010',
  's016',
  's022',
  's023',
  's060',
  's024',
  's026',
  's025',
  's061',
  's062',
  's027',
  's059',
  's029',
  's030',
  's031',
  's055',
  's032',
  's033',
  's034',
  's036',
  's035',
];

const ELECTRONIC_PARTS = [
  's008',
  's009',
  's010',
  's022',
  's023',
  's060',
  's025',
  's061',
  's062',
  's027',
  's059',
  's029',
  's030',
  's032',
  's033',
  's034',
  's036',
  's063',
  's064',
  's065',
];

const HYDROGEN_PARTS = [
  's001',
  's057',
  's003',
  's058',
  's005',
  's007',
  's008',
  's009',
  's010',
  's022',
  's023',
  's060',
  's024',
  's026',
  's025',
  's061',
  's062',
  's027',
  's059',
  's029',
  's030',
  's031',
  's055',
  's032',
  's033',
  's034',
  's036',
  's063',
  's064',
  's065',
  's035',
];

const HYBRID_PARTS = [
  's001',
  's057',
  's003',
  's058',
  's005',
  's007',
  's008',
  's009',
  's010',
  's022',
  's023',
  's060',
  's024',
  's026',
  's025',
  's061',
  's062',
  's027',
  's059',
  's029',
  's030',
  's031',
  's055',
  's032',
  's033',
  's034',
  's036',
  's063',
  's064',
  's065',
  's035',
];

const ETC_PARTS = [
  's001',
  's057',
  's003',
  's058',
  's005',
  's007',
  's008',
  's009',
  's010',
  's021',
  's022',
  's023',
  's060',
  's024',
  's026',
  's025',
  's061',
  's062',
  's027',
  's059',
  's029',
  's030',
  's031',
  's055',
  's032',
  's033',
  's034',
  's036',
  's063',
  's064',
  's065',
  's035',
];

const WATCHED_PROPS = [
  'pp_14',
  'pp_22',
  'pp_28',
  'pp_32',
  'pp_33',
  'pp_34',
  'pp_39',
  'pp_52',
  'pp_53',
  'pp_54',
  'pp_55',
  'pp_63',
  'pp_73',
  'pp_74',
  'pp_78',
  'pp_81',
  'pp_82',
  'pp_94',
  'pp_99',
  'pp_100',
  'pp_102',
  'pp_103',
  'pp_107',
  'pp_118',
  'pp_137',
  'pp_139',
  'pp_144',
  'pp_152',
  'pp_153',
  'pp_154',
  'pp_162',
  'pp_168',
  'pp_172',
  'pp_174',
  'pp_173',
  'pp_179',
  'pp_194',
];

export {
  PC_VALID_DAYS,
  PC_BASE_BLUEPRINT,
  PC_STATUS_BLUEPRINT,
  PARTS_LEFT,
  PARTS_RIGHT,
  PARTS_TOP,
  PARTS_BOTTOM,
  PARTS_ALL,
  ACCIDENT_STATUS,
  REPAIR_STATUS,
  MAIN_FRAMES,
  PARTS_TOP_M,
  PARTS_LEFT_M,
  PARTS_RIGHT_M,
  PARTS_BOTTOM_M,
  ACCIDENT_STATUS_TITLE,
  REPAIR_STATUS_TITLE,
  GASOLINE_PARTS,
  DIESEL_PARTS,
  LPG_PARTS,
  ELECTRONIC_PARTS,
  HYDROGEN_PARTS,
  HYBRID_PARTS,
  ETC_PARTS,
  WATCHED_PROPS,
};
