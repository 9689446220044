import { Button } from 'primereact/button';
import React from 'react';

export const DeleteButton = ({ onDelete, disabled }) => {
  return (
    <Button
      disabled={disabled}
      label="삭제"
      icon="pi pi-trash"
      className="p-button-outlined p-button-danger"
      onClick={onDelete}
    />
  );
};
