import React, { useEffect, useRef, useState } from 'react';
import _, { wrap } from 'lodash';

import { BlockUI } from 'primereact/blockui';
import { Menubar } from 'primereact/menubar';
import PerformanceCheckReport from '../../components/PerformanceCheck/PerformanceCheckReport';
import { ServiceProvider } from '../../services';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';
import { useRecoilValueLoadable } from 'recoil';
import { myInfoSelector } from '../../recoil/selectors';
import { Operations } from '../../services/PerformanceCheckService';

const performanceCheckService = ServiceProvider.performanceCheck;
const associationService = ServiceProvider.association;
const userService = ServiceProvider.user;
const PerformanceCheckViewer = ({
  match: {
    params: { statementNumber, dataId },
  },
}) => {
  const reportRef = useRef(null);
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const [officialSealUrls, setOfficialSealUrls] = useState({
    association: '',
    inspector: '',
  });
  const [sealLoading, setsealLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);

  const [loading, setLoading] = useState(false);
  const [checkData, setCheckData] = useState(null);
  const [checkPhotos, setCheckPhotos] = useState(null);
  const [data, setData] = useState({
    paymentInformation: null,
    association: null,
    inspector: null,
    vehicleInformation: null,
    customerCompany: null,
  });
  const [tempData, setTempData] = useState(null);
  const [printCount, setPrintCount] = useState(0);
  const putPrintCount = async () => {
    try {
      const data = await Operations.countPrint(dataId, printCount + 1);
      if (data) {
        console.log(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => {
      if (!officialSealUrls.association || !officialSealUrls.inspector)
        return window.cerp.toast.warn(
          '직인 이미지 로딩중',
          '잠시 후 다시 시도해주세요.'
        );
      const paperWrapper = reportRef.current.querySelectorAll('.paper_wrapper');

      const papers = reportRef.current.querySelectorAll('.paper');
      if (papers.length === 0) return false;

      const now = dayjs().format('YYYY-MM-DD HH:mm:ss');
      for (let i = 0; i < papers.length; i++) {
        const page = papers[i];

        const parts = page.querySelectorAll('.cerp-pp');
        for (let j = 0; j < parts.length; j++) {
          const part = parts[j];
          part.style.fontSize = '1rem';
        }

        const watermark = buildWatermark(now);
        page.appendChild(watermark);
      }

      return reportRef.current;
    },
    onBeforePrint: async () => {
      setLoading(true);
    },
    onAfterPrint: async () => {
      const watermarks = reportRef.current.querySelectorAll('.cerp-watermark');
      _.map(watermarks, (watermark) => watermark.remove());

      const parts = reportRef.current.querySelectorAll('.cerp-pp');
      _.map(parts, (part) => (part.style.fontSize = '1.25rem'));

      setLoading(false);
      // console.log('complete print');
      try {
        const data = await putPrintCount();
        if (data) {
          console.log('프린트 출력 완료, 횟수 저장', data);
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handlePDF = async () => {
    setLoading(true);
    const papers = reportRef.current.querySelectorAll('.paper');
    if (papers.length === 0) return false;

    const now = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const pageImages = [];
    const reportPDF = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
      compress: true,
    });

    for (let i = 0; i < papers.length; i++) {
      const page = papers[i];
      const watermark = buildWatermark(now);
      page.appendChild(watermark);

      const canvas = await html2canvas(page, {
        scale: 2,
        logging: true,
        allowTaint: false,
        useCORS: true,
        imageTimeout: 0,
        backgroundColor: 'transparent',
      });

      const pageImage = canvas.toDataURL('image/png');
      pageImages.push(pageImage);
      page.removeChild(watermark);
    }

    for (let i = 0; i < pageImages.length; i++) {
      reportPDF.addImage(
        pageImages[i],
        'PNG',
        0,
        0,
        210,
        297,
        `PAGE-${i}`,
        'NONE',
        0
      );
      if (i < pageImages.length - 1) reportPDF.addPage();
    }

    reportPDF.save(`성능점검기록부_${statementNumber}.pdf`);
    setLoading(false);
  };

  const buildWatermark = (now) => {
    const watermark = document.createElement('div');
    _.map(
      _.split(
        'cerp-watermark absolute top-50 left-0 right-0 opacity-40 text-gray-500 font-bold p-4rem text-center text-4xl origin-center',
        ' '
      ),
      (cls) => watermark.classList.add(cls)
    );
    watermark.style.transform = 'rotate(-30deg)';
    const text = document.createTextNode(`출력일시 : ${now}`);
    watermark.appendChild(text);

    return watermark;
  };

  useEffect(() => {
    setLoading(true);

    (async () => {
      const { data, error } = await performanceCheckService.getData(dataId);

      const userInfo = await myInfoLoadable.contents;
      if (userInfo?.loginId.includes('jw')) {
        setTempData(userInfo);
      }

      if (data) {
        const {
          performanceCheckData: {
            checkData: checkDataRaw,
            paymentInformation: paymentInformationRaw,
            association: associationRaw,
            inspector: inspectorRaw,
            customerCompany: customerCompanyRaw,
            vehicleInformation: vehicleInformationRaw,
          },
          performanceCheckPhotos,
        } = data;

        const checkData = JSON.parse(checkDataRaw);
        const paymentInformation = JSON.parse(paymentInformationRaw);
        const association = JSON.parse(associationRaw);
        const inspector = JSON.parse(inspectorRaw);
        const customerCompany = JSON.parse(customerCompanyRaw);
        const vehicleInformation = JSON.parse(vehicleInformationRaw);

        setPrintCount(data.performanceCheckData.printCount);
        setCheckData(checkData);
        setCheckPhotos(performanceCheckPhotos);
        setData((ps) => ({
          ...ps,
          paymentInformation,
          association,
          inspector,
          vehicleInformation,
          customerCompany,
        }));
      }

      if (error) {
        window.cerp.dialog.error(
          '존재하지 않는 성능지번호 입니다.',
          `[${statementNumber}]`
        );
      }
    })();

    setLoading(false);
  }, [dataId, statementNumber, myInfoLoadable.contents]);

  const [images, setImages] = useState([]);
  useEffect(() => {
    if (!images.length) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [images.length]);

  async function getEncodedImgUrl(imageUrl) {
    setImgLoading(true);
    try {
      const url = _.startsWith(imageUrl, 'http')
        ? imageUrl
        : process.env.REACT_APP_CLOUDFRONT_BASE_URL + imageUrl;
      const { data: encodedUrl } = await performanceCheckService.getBase64Url(
        url
      );
      return 'data:image/png;base64, ' + encodedUrl;
    } catch (error) {
      console.log(error);
    } finally {
      setImgLoading(false);
    }
  }

  async function getAssociationSealUrl(association) {
    const { associationId } = association;
    setsealLoading(true);
    try {
      const {
        data: { officialSealPath },
      } = await associationService.getDataMobile(associationId);

      if (officialSealPath) {
        const encodedUrl = await getEncodedImgUrl(officialSealPath);
        setOfficialSealUrls((ps) => ({ ...ps, association: encodedUrl }));
      }
    } catch (error) {
      console.log('official seal load fail');
      window.cerp.toast.error('official seal load fail');
    } finally {
      setsealLoading(false);
    }
  }

  async function getUserSealUrl(inspector) {
    const { userId } = inspector;
    setsealLoading(true);
    try {
      const {
        data: { officialSealURL },
      } = await userService.getDataExternal(userId);

      if (officialSealURL) {
        const encodedUrl = await getEncodedImgUrl(officialSealURL);
        setOfficialSealUrls((ps) => ({ ...ps, inspector: encodedUrl }));
      }
    } catch (error) {
      console.log('official seal load fail');
      window.cerp.toast.error('inspector official seal load fail');
    } finally {
      setsealLoading(false);
    }
  }

  useEffect(() => {
    if (!!data.association) {
      if (!officialSealUrls.association) {
        getAssociationSealUrl(data.association);
      }
    }
  }, [data.association]);

  useEffect(() => {
    if (!!data.inspector) {
      if (!officialSealUrls.inspector) {
        getUserSealUrl(data.inspector);
      }
    }
  }, [data.inspector]);

  useEffect(() => {
    if (!officialSealUrls.inspector || !officialSealUrls.association) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [officialSealUrls.association, officialSealUrls.inspector]);

  useEffect(() => {
    (async () => {
      if (checkPhotos && checkPhotos.length > 0) {
        const OUT_FRONT = 'OUT_FRONT';
        const OUT_REAR = 'OUT_REAR';

        const frontOrigins = _.filter(checkPhotos, { partCode: OUT_FRONT });
        const rearOrigins = _.filter(checkPhotos, { partCode: OUT_REAR });
        const frontImage = [...frontOrigins].shift();
        const rearImage = [...rearOrigins].shift();
        const originImages = [frontImage, rearImage];

        async function getFormattedImages(images) {
          return await Promise.all(
            images.map(async (img, idx) => {
              const encodedUrl = await getEncodedImgUrl(_.get(img, 'filePath'));
              const alt =
                _.get(img, 'partCode') === OUT_FRONT ? '전면' : '후면';

              img.src = encodedUrl;
              img.alt = alt;

              return img;
            })
          );
        }

        const formattedImages = await getFormattedImages(originImages);

        setImages(formattedImages);
      }
    })();
  }, [checkPhotos]);

  return (
    <div className="pcr_wrapper">
      <BlockUI
        fullScreen
        blocked={loading || imgLoading || sealLoading}
        template={
          loading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
      >
        <Menubar
          className="sticky top-0 z-1"
          model={[
            {
              label: '출력하기',
              icon: 'pi pi-fw pi-print',
              command: handlePrint,
            },
            {
              label: 'PDF 다운로드',
              icon: 'pi pi-fw pi-download',
              command: handlePDF,
            },
          ]}
          start={
            <label className="text-2xl font-semibold mr-2">
              성능점검기록부
            </label>
          }
        />
        <div className="flex flex-row align-items-center justify-content-center py-3">
          {checkData && data && checkPhotos && (
            <PerformanceCheckReport
              setLoading={setLoading}
              officialSealUrls={officialSealUrls}
              ref={reportRef}
              data={checkData}
              photos={checkPhotos}
              images={images}
              payment={_.get(data, 'paymentInformation')}
              association={_.get(data, 'association')}
              inspector={_.get(data, 'inspector')}
              customerCompany={_.get(data, 'customerCompany')}
              vehicleInformation={_.get(data, 'vehicleInformation')}
              tempData={tempData}
            />
          )}
        </div>
      </BlockUI>
      {/*{_.map(reportCaptureData, data => (<img src={data} style={{width: '210mm'}} />))}*/}
    </div>
  );
};

export default PerformanceCheckViewer;
