import React, { forwardRef, useState } from 'react';

import { Checkbox } from 'primereact/checkbox';
import { classNames } from 'primereact/utils';

export const GroupTitledCheckRadio = forwardRef((props, ref) => {
  const {
    name,
    title = '',
    value,
    defaultValue = '',
    items = [],
    onChange,
    error,
    disabled = false,
    readOnly = false,
  } = props;

  function handleCheckboxChange(checkedValue) {
    if (value === checkedValue) {
      onChange(defaultValue);
    } else {
      onChange(checkedValue);
    }
  }

  return (
    <>
      <div className={classNames('p-inputgroup h-full', { 'p-error': error })}>
        <div className=" w-full justify-content-center font-medium text-center min-w-3 w-4 max-w-5 flex align-items-center p-inputgroup-addon">
          {title}
        </div>
        <div
          className={classNames(
            'p-inputgroup-addon bg-transparent w-full flex flex-auto flex-wrap align-items-center justify-content-start gap-3',
            { 'bg-gray-100': disabled }
          )}
        >
          {items.map((item, idx) => (
            <div key={`${name}_${idx}`}>
              <div className="flex flex-auto align-items-center justify-content-start gap-1">
                <Checkbox
                  inputRef={ref}
                  inputId={`trb_${props.id || name}_${idx}`}
                  name={name}
                  value={item.value}
                  onChange={() => handleCheckboxChange(item.value)}
                  checked={value === item.value}
                  disabled={disabled}
                  readOnly={readOnly}
                />
                <label htmlFor={`trb_${props.id || name}_${idx}`}>
                  {item.label}
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
});
