import React, { useEffect, useState } from 'react';
import { Card } from 'primereact/card';
import { Panel } from 'primereact/panel';
import Claim from '../../services/ClaimService';

const Dashboard = () => {
  const IntFormatter = new Intl.NumberFormat('ko-KR', { style: 'decimal' });
  const [data, setData] = useState({
    claimReceiptCount: 0,
    claimWaitingCount: 0,
    claimCompleteCount: 0,
    claimRejectCount: 0,
    claimCarInCount: 0,
    claimRepairCount: 0,
    claimUnderRepairCount: 0,
    claimRepairCompleteCount: 0,
    claimNotRepairedCount: 0,
    claimExclusionCount: 0,
    claimMonthCount: 0,
    claimInAgreementCount: 0, // 외판 합의중
    claimAgreementCount: 0, // 외판 합의 완료
    claimPaymentTerminationCount: 0, // 외판 지급종결
  });

  const getData = async () => {
    try {
      const data = await Claim.homeInfo();
      if (data) {
        setData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const today = new Date();

  const CommonPanel = ({ title, value, type }) => {
    return (
      <div className="md:col-4 col-6">
        <Panel header={title}>
          <div className="text-lg">
            {IntFormatter.format(value) + `${!type ? ' 건' : ' %'}`}
          </div>
        </Panel>
      </div>
    );
  };

  return (
    <Card title="사고 관리" className="dashboard-h p-2">
      <div className="pr-3 pb-2 flex justify-content-end align-items-center gap-2">
        <i className="pi pi-database" />
        {today.getMonth() + 1 + ' 월'}
      </div>
      <div className="grid text-center">
        <CommonPanel title="고객 접수중" value={data.claimReceiptCount} />
        <CommonPanel
          title="서류접수(고객/매매상사) 대기중"
          value={data.claimWaitingCount}
        />
        <CommonPanel
          title="서류접수(고객/매매상사) 완료"
          value={data.claimCompleteCount}
        />
        <CommonPanel title="거절안내 완료" value={data.claimRejectCount} />
        <CommonPanel title="입고안내 완료" value={data.claimCarInCount} />
        <CommonPanel title="입고점검 완료" value={data.claimRepairCount} />
        <CommonPanel title="수리 진행중" value={data.claimUnderRepairCount} />
        <CommonPanel title="수리 완료" value={data.claimRepairCompleteCount} />
        <CommonPanel title="미수리" value={data.claimNotRepairedCount} />
        <CommonPanel title="당사면책" value={data.claimExclusionCount} />
        <CommonPanel title="지급종결" value={data.claimMonthCount} />
        <CommonPanel title="외판 합의중" value={data.claimInAgreementCount} />
        <CommonPanel title="외판 합의완료" value={data.claimAgreementCount} />
        <CommonPanel
          title="외판 지급종결"
          value={data.claimPaymentTerminationCount}
        />
      </div>
    </Card>
  );
};

export default Dashboard;
