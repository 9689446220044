import * as Diff from 'deep-object-diff';

import {
  ACCIDENT_STATUS,
  PARTS_ALL,
  PARTS_BOTTOM,
  PARTS_LEFT,
  PARTS_RIGHT,
  PARTS_TOP,
  PC_VALID_DAYS,
  REPAIR_STATUS,
} from '../../constants/PerformanceCheckConstants';
import React, { forwardRef, useEffect, useState } from 'react';

import BP from '../../assets/images/blueprint.png';
import BPBottom from '../../assets/images/bp-bottom.png';
import BPLeft from '../../assets/images/bp-left.png';
import BPRight from '../../assets/images/bp-right.png';
import BPTop from '../../assets/images/bp-top.png';
import Checked from '../../assets/icons/checked.png';
import { CommonUtil } from '../../utils/commonUtil';
import { ServiceProvider } from '../../services';
import Unchecked from '../../assets/icons/unchecked.png';
import { YN } from '../../constants/Constants';
import _ from 'lodash';
import dayjs from 'dayjs';

const associationService = ServiceProvider.association;
const userService = ServiceProvider.user;
const performanceCheckService = ServiceProvider.performanceCheck;

const setCheckState = (raw, key, compare) => {
  if (_.isArray(compare))
    return _.includes(compare, _.get(raw, key)) ? Checked : Unchecked;
  return _.get(raw, key) === compare ? Checked : Unchecked;
};

const setSubCheckState = (raw, keys, compares) => {
  let count = 0;
  for (const key of keys) {
    if (_.includes(compares, _.get(raw, key))) count++;
  }
  return count === 0 ? Unchecked : Checked;
};

const setPartState = (value) => {
  let label = '';

  switch (value) {
    case '1':
      return '';
    case '2':
      return 'X';
    case '3':
      return 'W';
    case '4':
      return 'C';
    case '5':
      return 'A';
    case '6':
      return 'U';
    case '7':
      return 'T';
  }

  return label;
};

const PerformanceCheckReportMobile = forwardRef(
  (
    {
      data,
      photos,
      payment,
      association,
      inspector,
      customerCompany,
      vehicleInformation,
    },
    ref
  ) => {
    const [officialSealUrls, setOfficialSealUrls] = useState({});
    const [images, setImages] = useState([]);

    async function getEncodedImgUrl(imageUrl) {
      const url = _.startsWith(imageUrl, 'http')
        ? imageUrl
        : process.env.REACT_APP_S3_BASE_URL + imageUrl;
      const { data: encodedUrl } = await performanceCheckService.getBase64Url(
        url
      );
      return 'data:image/png;base64, ' + encodedUrl;
    }

    async function getAssociationSealUrl(association) {
      const { associationId } = association;
      try {
        const {
          data: { officialSealPath },
        } = await associationService.getDataMobile(associationId);

        if (officialSealPath) {
          const encodedUrl = await getEncodedImgUrl(officialSealPath);
          setOfficialSealUrls((ps) => ({ ...ps, association: encodedUrl }));
        }
      } catch (error) {
        console.log('official seal load fail');
      }
    }

    async function getUserSealUrl(inspector) {
      const { userId } = inspector;
      try {
        const {
          data: { officialSealURL },
        } = await userService.getDataExternal(userId);

        if (officialSealURL) {
          const encodedUrl = await getEncodedImgUrl(officialSealURL);
          setOfficialSealUrls((ps) => ({ ...ps, inspector: encodedUrl }));
        }
      } catch (error) {
        console.log('official seal load fail');
      }
    }

    function renderSignImages() {
      const [association, shop] = _.get(data, 'CHCK_ENTRPS_NM').split('/');

      return (
        <div className="vertical-align-bottom">
          <span
            className="text-lg"
            style={{
              position: 'relative',
              paddingRight: '60px',
            }}
          >
            {association}
            <img
              src={_.get(officialSealUrls, 'association')}
              alt="association_officialSeal"
              style={{
                position: 'absolute',
                width: '50px',
                top: '-50%',
                right: '5px',
              }}
            />
          </span>
          <span className="text-lg mr-4">{`/ ${shop} `}</span>
          <span className="text-lg mr-4">{_.get(data, 'INSCTR_NM')}</span>
          <span
            style={{
              position: 'relative',
            }}
          >
            (서명 또는 인)
            {officialSealUrls.inspector && (
              <img
                src={_.get(officialSealUrls, 'inspector')}
                alt="inspector_officialSeal"
                style={{
                  position: 'absolute',
                  width: '50px',
                  top: '-120%',
                  left: 0,
                }}
              />
            )}
          </span>
        </div>
      );
    }

    function getEndDate(checkDate) {
      const date = dayjs(checkDate);
      const end = date.add(PC_VALID_DAYS - 1, 'day').format('YYYY-MM-DD');
      return end;
    }

    useEffect(() => {
      (async () => {
        if (association !== null) {
          await getAssociationSealUrl(association);
        }

        if (inspector !== null) {
          await getUserSealUrl(inspector);
        }
      })();
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [association, inspector]);

    useEffect(() => {
      (async () => {
        if (photos && photos.length > 0) {
          const OUT_FRONT = 'OUT_FRONT';
          const OUT_REAR = 'OUT_REAR';
          // const frontOrigins = _.sortBy(
          //   _.filter(photos, { partCode: OUT_FRONT }),
          //   ['regTime']
          // );
          // const rearOrigins = _.sortBy(
          //   _.filter(photos, { partCode: OUT_REAR }),
          //   ['regTime']
          // );

          // const originImages = [frontOrigins.at(-1), rearOrigins.at(-1)];

          const frontOrigins = _.filter(photos, { partCode: OUT_FRONT });
          const rearOrigins = _.filter(photos, { partCode: OUT_REAR });
          const frontImage = [...frontOrigins].shift();
          const rearImage = [...rearOrigins].shift();
          const originImages = [frontImage, rearImage];

          async function getFormattedImages(images) {
            return await Promise.all(
              images.map(async (img, idx) => {
                const encodedUrl = await getEncodedImgUrl(
                  _.get(img, 'filePath')
                );
                const alt =
                  _.get(img, 'partCode') === OUT_FRONT ? '전면' : '후면';

                img.src = encodedUrl;
                img.alt = alt;

                return img;
              })
            );
          }

          const formattedImages = await getFormattedImages(originImages);

          setImages(formattedImages);
        }
      })();
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [photos]);

    return (
      <div ref={ref} id="performance-check-report" className="pcr">
        <div className="paper">
          <table className="w-full h-full">
            <thead />
            <tbody>
              <tr className="h-1rem">
                <td className="text-xs">
                  ◼︎ 자동차관리법 시행규칙 [별지 제82호서식] &lt;개정
                  2021.1.19.&gt;
                </td>
                <td className="text-xs text-right">(1/4쪽)</td>
              </tr>

              <tr>
                <td colSpan="2" className="content">
                  <table className="w-full h-full">
                    <thead />
                    <tbody>
                      <tr>
                        <td className="p-0 border-bottom-2">
                          <table className="w-full h-full bg-gray-100 px-2 py-2">
                            <thead />
                            <tbody>
                              <tr>
                                <td className="pt-1 font-bold text-xl text-center line-height-1">
                                  <div>중고자동차 성능ㆍ상태점검기록부</div>
                                  <div className="flex flex-row align-items-center justify-content-center">
                                    <div className="mx-1">(</div>
                                    <img
                                      src={Unchecked}
                                      className="cbib mx-1"
                                      alt=""
                                    />{' '}
                                    자동차가격조사ㆍ산정 선택
                                    <div className="mx-1">)</div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td className="text-center relative">
                                  <div
                                    className="absolute font-bold bottom-0"
                                    style={{ left: 6 }}
                                  >
                                    제 {_.get(data, 'PRFOMNC_CHCK_NO')} 호
                                  </div>
                                  <div className="text-xs">
                                    ※ 자동차 가격조사ㆍ산정은 매수인이 원하는
                                    경우 제공하는 서비스 입니다.
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td className="p-0">
                          <table className="w-full h-full">
                            <thead />
                            <tbody>
                              <tr>
                                <td className="p-0 sub-title">
                                  <table className="w-full">
                                    <thead />
                                    <tbody>
                                      <tr>
                                        <td className="pt-1 font-bold text-xl text-center line-height-1">
                                          자동차 기본정보
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="text-xs text-center">
                                          (가격산정 기준가격은 매수인이
                                          자동차가격조사ㆍ산정을 원하는 경우에만
                                          적습니다)
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0 sub-content">
                                  <table className="w-full h-full text-sm">
                                    <colgroup>
                                      <col width="19.77349943mm" />
                                      <col width="24.16761042mm" />
                                      <col width="17.57644394mm" />
                                      <col width="28.5617214mm" />
                                      <col width="13.18233296mm" />
                                      <col width="2.197055493mm" />
                                      <col width="6.591166478mm" />
                                      <col width="17.57644394mm" />
                                      <col width="26.36466591mm" />
                                      <col width="17.57644394mm" />
                                      <col width="20.43261608mm" />
                                    </colgroup>

                                    <thead />
                                    <tbody>
                                      <tr>
                                        <td className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold">
                                          ① 차명
                                        </td>
                                        <td
                                          colSpan="7"
                                          className="border-right-dotted border-bottom-dotted"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-between">
                                            <span>{_.get(data, 'CNM')}</span>
                                            <span>
                                              (세부모델 :{' '}
                                              {_.get(data, 'cnmDetail') ||
                                                '\u00A0\u00A0\u00A0\u00A0'}
                                              )
                                            </span>
                                          </div>
                                        </td>
                                        <td className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold">
                                          ② 자동차등록번호
                                        </td>
                                        <td
                                          colSpan="2"
                                          className="border-bottom-dotted"
                                        >
                                          {_.get(data, 'VHRNO')}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold">
                                          ③ 연식
                                        </td>
                                        <td
                                          colSpan="4"
                                          className="border-right-dotted border-bottom-dotted"
                                        >
                                          {_.get(data, 'PRYE')}
                                        </td>
                                        <td
                                          colSpan="3"
                                          className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold"
                                        >
                                          ④ 검사유효기간
                                        </td>
                                        <td
                                          colSpan="3"
                                          className="border-bottom-dotted"
                                        >
                                          {dayjs(
                                            _.get(data, 'INSPT_VALID_PD_BGNDE')
                                          ).format('YYYY년 MM월 DD일')}{' '}
                                          ~{' '}
                                          {dayjs(
                                            _.get(data, 'INSPT_VALID_PD_ENDDE')
                                          ).format('YYYY년 MM월 DD일')}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold">
                                          ⑤ 최초등록일
                                        </td>
                                        <td
                                          colSpan="4"
                                          className="border-right-dotted border-bottom-dotted"
                                        >
                                          {dayjs(
                                            _.get(data, 'FRST_REGIST_DE')
                                          ).format('YYYY 년 MM 월 DD일')}
                                        </td>
                                        <td
                                          rowSpan="2"
                                          colSpan="3"
                                          className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold"
                                        >
                                          ⑦ 변속기종류
                                        </td>
                                        <td
                                          rowSpan="2"
                                          colSpan="3"
                                          className="border-bottom-dotted"
                                        >
                                          <div className="flex flex-column">
                                            <div className="flex flex-row align-items-center justify-content-start gap-3">
                                              <div className="flex flex-row align-items-center justify-content-start gap-1">
                                                <img
                                                  className="cbi"
                                                  alt="checkbox"
                                                  src={setCheckState(
                                                    data,
                                                    'GRBX_KND_CODE',
                                                    'A'
                                                  )}
                                                />
                                                자동
                                              </div>
                                              <div className="flex flex-row align-items-center justify-content-start gap-1">
                                                <img
                                                  className="cbi"
                                                  alt="checkbox"
                                                  src={setCheckState(
                                                    data,
                                                    'GRBX_KND_CODE',
                                                    'M'
                                                  )}
                                                />
                                                수동
                                              </div>
                                              <div className="flex flex-row align-items-center justify-content-start gap-1">
                                                <img
                                                  className="cbi"
                                                  alt="checkbox"
                                                  src={setCheckState(
                                                    data,
                                                    'GRBX_KND_CODE',
                                                    'S'
                                                  )}
                                                />
                                                세미오토
                                              </div>
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-3">
                                              <div className="flex flex-row align-items-center justify-content-start gap-1">
                                                <img
                                                  className="cbi"
                                                  alt="checkbox"
                                                  src={setCheckState(
                                                    data,
                                                    'GRBX_KND_CODE',
                                                    'C'
                                                  )}
                                                />
                                                무단변속기
                                              </div>
                                              <div className="flex flex-row align-items-center justify-content-start gap-1">
                                                <img
                                                  className="cbi"
                                                  alt="checkbox"
                                                  src={setCheckState(
                                                    data,
                                                    'GRBX_KND_CODE',
                                                    'X'
                                                  )}
                                                />
                                                기타 (
                                                {_.get(data, 'GRBX_KND_DTLS') ||
                                                  '\u00A0\u00A0\u00A0\u00A0'}
                                                )
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold">
                                          ⑥ 차대번호
                                        </td>
                                        <td
                                          colSpan="4"
                                          className="border-right-dotted border-bottom-dotted"
                                        >
                                          {/* <span>{_.get(data, 'VIN')}</span> */}
                                          {_.get(data, 'VIN').length === 11
                                            ? `${_.get(data, 'VIN')}${_.get(
                                                vehicleInformation,
                                                'vinRemain'
                                              )}`
                                            : `${_.get(data, 'VIN')}`}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold">
                                          ⑧ 사용연료
                                        </td>
                                        <td
                                          colSpan="10"
                                          className="border-bottom-dotted"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'USE_FUEL_CODE',
                                                  'a'
                                                )}
                                              />
                                              가솔린
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'USE_FUEL_CODE',
                                                  'b'
                                                )}
                                              />
                                              디젤
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'USE_FUEL_CODE',
                                                  'c'
                                                )}
                                              />
                                              LPG
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'USE_FUEL_CODE',
                                                  'x'
                                                )}
                                              />
                                              하이브리드
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'USE_FUEL_CODE',
                                                  'd'
                                                )}
                                              />
                                              전기
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'USE_FUEL_CODE',
                                                  'e'
                                                )}
                                              />
                                              수소전기
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'USE_FUEL_CODE',
                                                  'z'
                                                )}
                                              />
                                              기타
                                            </div>
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="border-right-dotted bg-gray-100 font-semibold">
                                          ⑨ 원동기형식
                                        </td>
                                        <td className="border-right-dotted">
                                          {_.get(data, 'MTRS_FOM')}
                                        </td>
                                        <td className="border-right-dotted bg-gray-100 font-semibold">
                                          ⑩ 보증유형
                                        </td>
                                        {/* TODO - 없음 */}
                                        <td
                                          colSpan="5"
                                          className="border-right-dotted"
                                          style={{
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                          }}
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'ASSRNC_TY_SE_CODE',
                                                  '1'
                                                )}
                                              />
                                              자가보증
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'ASSRNC_TY_SE_CODE',
                                                  '2'
                                                )}
                                              />
                                              보험사보증
                                              {/* <span>
                                                (보험사 :{' '}
                                                {_.get(
                                                  _.find(insurances, {
                                                    value: _.get(
                                                      data,
                                                      'ICNY_CODE'
                                                    ),
                                                  }),
                                                  'codeData.companyNameAbbreviation'
                                                ) || '\u00A0\u00A0\u00A0\u00A0'}
                                                )
                                              </span> */}
                                            </div>
                                          </div>
                                        </td>
                                        <td className="border-right-dotted bg-gray-100 font-semibold">
                                          가격산정 기준가격
                                        </td>
                                        <td colSpan="2">
                                          <div className="flex flex-row align-items-center justify-content-between">
                                            <div
                                              className="flex-auto"
                                              style={{
                                                border: 1,
                                                borderStyle: 'dotted',
                                              }}
                                            >
                                              {'\u00A0'}
                                            </div>
                                            <div
                                              className="flex-auto"
                                              style={{
                                                borderTop: 1,
                                                borderRight: 1,
                                                borderBottom: 1,
                                                borderLeft: 0,
                                                borderStyle: 'dotted',
                                              }}
                                            >
                                              {'\u00A0'}
                                            </div>
                                            <div
                                              className="flex-auto"
                                              style={{
                                                borderTop: 1,
                                                borderRight: 1,
                                                borderBottom: 1,
                                                borderLeft: 0,
                                                borderStyle: 'dotted',
                                              }}
                                            >
                                              {'\u00A0'}
                                            </div>
                                            <div
                                              className="flex-auto"
                                              style={{
                                                borderTop: 1,
                                                borderRight: 1,
                                                borderBottom: 1,
                                                borderLeft: 0,
                                                borderStyle: 'dotted',
                                              }}
                                            >
                                              {'\u00A0'}
                                            </div>
                                            <div
                                              className="flex-auto"
                                              style={{
                                                borderTop: 1,
                                                borderRight: 1,
                                                borderBottom: 1,
                                                borderLeft: 0,
                                                borderStyle: 'dotted',
                                              }}
                                            >
                                              {'\u00A0'}
                                            </div>
                                            <div className="flex-auto">
                                              &nbsp;&nbsp;만원
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0 sub-title">
                                  <table className="w-full">
                                    <thead />
                                    <tbody>
                                      <tr>
                                        <td className="pt-1 font-bold text-xl text-center line-height-1">
                                          자동차 종합상태
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="text-xs text-center">
                                          (색상, 주요옵션, 가격조사ㆍ산정액 및
                                          특기사항은 매수인이
                                          자동차가격조사ㆍ산정을 원하는 경우에만
                                          적습니다.)
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0 sub-content">
                                  <table className="w-full h-full text-sm">
                                    <colgroup>
                                      <col width="19.77349943mm" />
                                      <col width="24.16761042mm" />
                                      <col width="17.57644394mm" />
                                      <col width="28.5617214mm" />
                                      <col width="13.18233296mm" />
                                      <col width="2.197055493mm" />
                                      <col width="6.591166478mm" />
                                      <col width="17.57644394mm" />
                                      <col width="26.36466591mm" />
                                      <col width="17.57644394mm" />
                                      <col width="20.43261608mm" />
                                    </colgroup>

                                    <thead />
                                    <tbody>
                                      <tr>
                                        <td className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold">
                                          ⑪ 사용이력
                                        </td>
                                        <td
                                          colSpan="3"
                                          className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold"
                                        >
                                          상태
                                        </td>
                                        <td
                                          colSpan="5"
                                          className="border-right-solid border-bottom-dotted bg-gray-100 font-semibold"
                                        >
                                          항목 / 해당부품
                                        </td>
                                        <td
                                          colSpan="2"
                                          className="border-bottom-dotted bg-gray-100 font-semibold"
                                        >
                                          가격조사ㆍ산정액 및 특기사항
                                        </td>
                                      </tr>

                                      <tr>
                                        <td
                                          rowSpan="2"
                                          className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold"
                                          style={{ wordBreak: 'keep-all' }}
                                        >
                                          주행거리 및 계기상태
                                        </td>
                                        <td
                                          colSpan="3"
                                          className="border-right-dotted border-bottom-dotted"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'GAUGE_FNCT_AT',
                                                  '0'
                                                )}
                                              />
                                              양호
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'GAUGE_FNCT_AT',
                                                  '1'
                                                )}
                                              />
                                              불량
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          rowSpan="2"
                                          colSpan="5"
                                          className="border-right-solid border-bottom-dotted"
                                        >
                                          현재 주행거리 &nbsp;
                                          <span className="font-bold">
                                            {CommonUtil.Unit.format(
                                              _.get(data, 'TRVL_DSTNC')
                                            )}
                                          </span>
                                          &nbsp; KM
                                        </td>
                                        <td className="border-right-dotted border-bottom-dotted text-right">
                                          만원
                                        </td>
                                        <td className="border-bottom-dotted text-right">
                                          <b> </b>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td
                                          colSpan="3"
                                          className="border-right-dotted border-bottom-dotted"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'TRVL_DSTNC_STTUS_CODE',
                                                  '3'
                                                )}
                                              />
                                              많음
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'TRVL_DSTNC_STTUS_CODE',
                                                  '2'
                                                )}
                                              />
                                              보통
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'TRVL_DSTNC_STTUS_CODE',
                                                  '1'
                                                )}
                                              />
                                              적음
                                            </div>
                                          </div>
                                        </td>
                                        <td className="border-right-dotted border-bottom-dotted text-right">
                                          만원
                                        </td>
                                        <td className="border-bottom-dotted text-right">
                                          <b> </b>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold">
                                          차대번호표기
                                        </td>
                                        <td
                                          colSpan="8"
                                          className="border-right-solid border-bottom-dotted"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'SAMENSS_CNFIRM_CODE',
                                                  '1'
                                                )}
                                              />
                                              양호
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'SAMENSS_CNFIRM_CODE',
                                                  '2'
                                                )}
                                              />
                                              부식
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'SAMENSS_CNFIRM_CODE',
                                                  '3'
                                                )}
                                              />
                                              훼손(오손)
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'SAMENSS_CNFIRM_CODE',
                                                  '4'
                                                )}
                                              />
                                              상이
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'SAMENSS_CNFIRM_CODE',
                                                  '5'
                                                )}
                                              />
                                              변조(변타)
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'SAMENSS_CNFIRM_CODE',
                                                  '6'
                                                )}
                                              />
                                              도말
                                            </div>
                                          </div>
                                        </td>
                                        <td className="border-right-dotted border-bottom-dotted text-right">
                                          만원
                                        </td>
                                        <td className="border-bottom-dotted text-right">
                                          <b> </b>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold">
                                          배출가스
                                        </td>
                                        <td
                                          colSpan="3"
                                          className="border-right-dotted border-bottom-dotted"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'CHK_CRMN_MESURE_VALUE',
                                                  'Y'
                                                )}
                                              />
                                              일산화탄소
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'CHK_HYDR_MESURE_VALUE',
                                                  'Y'
                                                )}
                                              />
                                              탄화수소
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'CHK_SMOKE_MESURE_VALUE',
                                                  'Y'
                                                )}
                                              />
                                              매연
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          colSpan="5"
                                          className="border-right-solid border-bottom-dotted"
                                        >
                                          {/* <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <span>
                                              {_.get(data, 'CRMN_MESURE_VALUE')}{' '}
                                              %,
                                            </span>
                                            <span>
                                              {_.get(data, 'HYDR_MESURE_VALUE')}{' '}
                                              ppm,
                                            </span>
                                            <span>
                                              {_.get(
                                                data,
                                                'SMOKE_MESURE_VALUE'
                                              )}{' '}
                                              %
                                            </span>
                                          </div>*/}
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <span>
                                              {_.get(
                                                data,
                                                'CRMN_MESURE_VALUE'
                                              ) === ''
                                                ? '0.0'
                                                : _.get(
                                                    data,
                                                    'CRMN_MESURE_VALUE'
                                                  )}{' '}
                                              %,
                                            </span>
                                            <span>
                                              {_.get(
                                                data,
                                                'HYDR_MESURE_VALUE'
                                              ) === ''
                                                ? '0'
                                                : _.get(
                                                    data,
                                                    'HYDR_MESURE_VALUE'
                                                  )}{' '}
                                              ppm,
                                            </span>
                                            <span>
                                              {_.get(
                                                data,
                                                'SMOKE_MESURE_VALUE'
                                              ) === ''
                                                ? '0'
                                                : _.get(
                                                    data,
                                                    'SMOKE_MESURE_VALUE'
                                                  )}{' '}
                                              %
                                            </span>
                                          </div>
                                        </td>
                                        <td className="border-right-dotted border-bottom-dotted text-right">
                                          만원
                                        </td>
                                        <td className="border-bottom-dotted text-right">
                                          <b> </b>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold">
                                          튜닝
                                        </td>
                                        <td
                                          colSpan="2"
                                          className="border-right-dotted border-bottom-dotted"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'UNLAW_STMD_AT',
                                                  '0'
                                                )}
                                              />
                                              없음
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'UNLAW_STMD_AT',
                                                  ['1', '2']
                                                )}
                                              />
                                              있음
                                            </div>
                                          </div>
                                        </td>
                                        <td className="border-right-dotted border-bottom-dotted">
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'UNLAW_STMD_AT',
                                                  '1'
                                                )}
                                              />
                                              적법
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'UNLAW_STMD_AT',
                                                  '2'
                                                )}
                                              />
                                              불법
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          colSpan="5"
                                          className="border-right-solid border-bottom-dotted"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'UNLAW_STMD_IEM_SE_CODE',
                                                  '1'
                                                )}
                                              />
                                              구조
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'UNLAW_STMD_IEM_SE_CODE',
                                                  '2'
                                                )}
                                              />
                                              장치
                                            </div>
                                          </div>
                                        </td>
                                        <td className="border-right-dotted border-bottom-dotted text-right">
                                          만원
                                        </td>
                                        <td className="border-bottom-dotted text-right">
                                          <b> </b>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold">
                                          특별이력
                                        </td>
                                        <td
                                          colSpan="3"
                                          className="border-right-dotted border-bottom-dotted"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'FLUD_AT',
                                                  '0'
                                                )}
                                              />
                                              없음
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'FLUD_AT',
                                                  ['1', '2']
                                                )}
                                              />
                                              있음
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          colSpan="5"
                                          className="border-right-solid border-bottom-dotted"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'FLUD_AT',
                                                  '1'
                                                )}
                                              />
                                              침수
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'FLUD_AT',
                                                  '2'
                                                )}
                                              />
                                              화재
                                            </div>
                                          </div>
                                        </td>
                                        <td className="border-right-dotted border-bottom-dotted text-right">
                                          만원
                                        </td>
                                        <td className="border-bottom-dotted text-right">
                                          <b> </b>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="border-right-dotted border-bottom-solid bg-gray-100 font-semibold">
                                          용도변경
                                        </td>
                                        <td
                                          colSpan="3"
                                          className="border-right-dotted border-bottom-solid"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'PRPOS_CHANGE_SE_CODE',
                                                  '0'
                                                )}
                                              />
                                              없음
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'PRPOS_CHANGE_SE_CODE',
                                                  ['1', '2', '3']
                                                )}
                                              />
                                              있음
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          colSpan="5"
                                          className="border-right-solid border-bottom-solid"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'PRPOS_CHANGE_SE_CODE',
                                                  '1'
                                                )}
                                              />
                                              렌트
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'PRPOS_CHANGE_SE_CODE',
                                                  '2'
                                                )}
                                              />
                                              리스
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'PRPOS_CHANGE_SE_CODE',
                                                  '3'
                                                )}
                                              />
                                              영업용
                                            </div>
                                          </div>
                                        </td>
                                        <td className="border-right-dotted border-bottom-dotted text-right">
                                          만원
                                        </td>
                                        <td className="border-bottom-dotted text-right">
                                          <b> </b>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold">
                                          색상
                                        </td>
                                        <td
                                          colSpan="3"
                                          className="border-right-dotted border-bottom-dotted"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'COLOR_AT',
                                                  '1'
                                                )}
                                              />
                                              무채색
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'COLOR_AT',
                                                  '2'
                                                )}
                                              />
                                              유채색
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          colSpan="5"
                                          className="border-right-dotted border-bottom-dotted"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'COLOR_CHANGE_AT',
                                                  '1'
                                                )}
                                              />
                                              전체도색
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'COLOR_CHANGE_AT',
                                                  '2'
                                                )}
                                              />
                                              색상변경
                                            </div>
                                          </div>
                                        </td>
                                        <td className="border-right-dotted border-bottom-dotted text-right">
                                          만원
                                        </td>
                                        <td className="border-bottom-dotted text-right">
                                          <b> </b>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="border-right-dotted border-bottom-solid bg-gray-100 font-semibold">
                                          주요옵션
                                        </td>
                                        <td
                                          colSpan="3"
                                          className="border-right-dotted border-bottom-solid"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'TODO',
                                                  '1'
                                                )}
                                              />
                                              없음
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'TODO',
                                                  '1'
                                                )}
                                              />
                                              있음
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          colSpan="5"
                                          className="border-right-dotted border-bottom-solid"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'MAIN_OPTN_VHCLE_RF_AT',
                                                  '1'
                                                )}
                                              />
                                              썬루프
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'MAIN_OPTN_ROAD_GUIDANCE_AT',
                                                  '1'
                                                )}
                                              />
                                              네비게이션
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'MAIN_OPTN_ETC_AT',
                                                  '1'
                                                )}
                                              />
                                              기타
                                            </div>
                                          </div>
                                        </td>
                                        <td className="border-right-dotted border-bottom-solid text-right">
                                          만원
                                        </td>
                                        <td className="border-bottom-solid text-right">
                                          <b> </b>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="border-right-dotted bg-gray-100 font-semibold">
                                          리콜대상
                                        </td>
                                        <td
                                          colSpan="3"
                                          className="border-right-dotted"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'RECALL_TRGET_AT',
                                                  '0'
                                                )}
                                              />
                                              해당없음
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'RECALL_TRGET_AT',
                                                  '1'
                                                )}
                                              />
                                              해당
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          colSpan="4"
                                          className="border-right-dotted bg-gray-100 font-semibold"
                                        >
                                          리콜이행
                                        </td>
                                        <td colSpan="3">
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={
                                                  _.get(
                                                    data,
                                                    'RECALL_TRGET_AT'
                                                  ) === '1'
                                                    ? setCheckState(
                                                        data,
                                                        'RECALL_FLFL_AT',
                                                        '1'
                                                      )
                                                    : Unchecked
                                                }
                                              />
                                              이행
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={
                                                  _.get(
                                                    data,
                                                    'RECALL_TRGET_AT'
                                                  ) === '1'
                                                    ? setCheckState(
                                                        data,
                                                        'RECALL_FLFL_AT',
                                                        '0'
                                                      )
                                                    : Unchecked
                                                }
                                              />
                                              미이행
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0 sub-title">
                                  <table className="w-full">
                                    <thead />
                                    <tbody>
                                      <tr>
                                        <td className="pt-1 font-bold text-xl text-center line-height-1">
                                          사고∙교환∙수리 등 이력
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="text-xs text-center">
                                          (가격조사ㆍ산정액 및 특기사항은
                                          매수인이 자동차가격조사ㆍ산정을 원하는
                                          경우에만 적습니다)
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td className="p-0">
                                  <table className="w-full h-full text-sm">
                                    <colgroup>
                                      <col width="19.77349943mm" />
                                      <col width="24.16761042mm" />
                                      <col width="17.57644394mm" />
                                      <col width="28.5617214mm" />
                                      <col width="13.18233296mm" />
                                      <col width="2.197055493mm" />
                                      <col width="6.591166478mm" />
                                      <col width="17.57644394mm" />
                                      <col width="26.36466591mm" />
                                      <col width="17.57644394mm" />
                                      <col width="20.43261608mm" />
                                    </colgroup>

                                    <thead />
                                    <tbody>
                                      <tr>
                                        <td
                                          colSpan="11"
                                          className="border-bottom-dotted"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start">
                                            <div className="mr-2">
                                              ※ 상태표시 부호 :
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <div className="flex flex-row align-items-center justify-content-start gap-1">
                                                <label
                                                  className="font-bold"
                                                  style={
                                                    {
                                                      /*{color: 'rgb(204,51,0)'}*/
                                                    }
                                                  }
                                                >
                                                  X
                                                </label>{' '}
                                                (교환),
                                              </div>
                                              <div className="flex flex-row align-items-center justify-content-start gap-1">
                                                <label
                                                  className="font-bold"
                                                  style={
                                                    {
                                                      /*{color: 'rgb(255,153,102)'}*/
                                                    }
                                                  }
                                                >
                                                  W
                                                </label>{' '}
                                                (판금 또는 용접),
                                              </div>
                                              <div className="flex flex-row align-items-center justify-content-start gap-1">
                                                <label
                                                  className="font-bold"
                                                  style={
                                                    {
                                                      /*{color: 'rgb(255,204,0)'}*/
                                                    }
                                                  }
                                                >
                                                  C
                                                </label>{' '}
                                                (부식),
                                              </div>
                                              <div className="flex flex-row align-items-center justify-content-start gap-1">
                                                <label
                                                  className="font-bold"
                                                  style={
                                                    {
                                                      /*{color: 'rgb(153,204,51)'}*/
                                                    }
                                                  }
                                                >
                                                  A
                                                </label>{' '}
                                                (흠집),
                                              </div>
                                              <div className="flex flex-row align-items-center justify-content-start gap-1">
                                                <label
                                                  className="font-bold"
                                                  style={
                                                    {
                                                      /*{color: 'rgb(51,153,0)'}*/
                                                    }
                                                  }
                                                >
                                                  U
                                                </label>{' '}
                                                (요철),
                                              </div>
                                              <div className="flex flex-row align-items-center justify-content-start gap-1">
                                                <label
                                                  className="font-bold"
                                                  style={
                                                    {
                                                      /*{color: 'rgb(41,109,169)'}*/
                                                    }
                                                  }
                                                >
                                                  T
                                                </label>{' '}
                                                (손상)
                                              </div>
                                            </div>
                                          </div>
                                          ※ 하단 항목은 승용차 기준이며, 기타
                                          자동차는 승용차에 준하여 표시
                                        </td>
                                      </tr>

                                      <tr>
                                        <td
                                          colSpan="11"
                                          className="border-bottom-dotted p-0"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-evenly relative">
                                            {/*<img src={BP} style={{height: '70mm'}} className="m-0 p-0 opacity-40" alt={'차량이미지'} />*/}
                                            {/*{*/}
                                            {/*  _.map(PARTS_LEFT, ({partPoint, rpos: [top, left]}) => (*/}
                                            {/*    <div*/}
                                            {/*      key={`key_${partPoint}`}*/}
                                            {/*      className="cerp-pp absolute text-center text-base font-bold"*/}
                                            {/*      style={{top, left}}>*/}
                                            {/*      {setPartState(_.get(data, partPoint))}*/}
                                            {/*    </div>*/}
                                            {/*  ))*/}
                                            {/*}*/}
                                            <div className="relative">
                                              <img
                                                src={BPLeft}
                                                style={{
                                                  minHeight: '70mm',
                                                  height: '70mm',
                                                  maxHeight: '70mm',
                                                }}
                                                className="m-0 p-0 opacity-70"
                                                alt={'차량이미지'}
                                              />
                                              {_.map(
                                                PARTS_LEFT,
                                                ({
                                                  partPoint,
                                                  rpos: [top, left],
                                                }) => (
                                                  <div
                                                    key={`key_${partPoint}`}
                                                    className="cerp-pp absolute text-center text-base font-bold"
                                                    style={{ top, left }}
                                                  >
                                                    {setPartState(
                                                      _.get(data, partPoint)
                                                    )}
                                                  </div>
                                                )
                                              )}
                                            </div>
                                            <div className="relative">
                                              <img
                                                src={BPTop}
                                                style={{
                                                  minHeight: '70mm',
                                                  height: '70mm',
                                                  maxHeight: '70mm',
                                                }}
                                                className="m-0 p-0 opacity-70"
                                                alt={'차량이미지'}
                                              />
                                              {_.map(
                                                PARTS_TOP,
                                                ({
                                                  partPoint,
                                                  rpos: [top, left],
                                                }) => (
                                                  <div
                                                    key={`key_${partPoint}`}
                                                    className="cerp-pp absolute text-center text-base font-bold"
                                                    style={{ top, left }}
                                                  >
                                                    {setPartState(
                                                      _.get(data, partPoint)
                                                    )}
                                                  </div>
                                                )
                                              )}
                                            </div>
                                            <div className="relative">
                                              <img
                                                src={BPBottom}
                                                style={{
                                                  minHeight: '70mm',
                                                  height: '70mm',
                                                  maxHeight: '70mm',
                                                }}
                                                className="m-0 p-0 opacity-70"
                                                alt={'차량이미지'}
                                              />
                                              {_.map(
                                                PARTS_BOTTOM,
                                                ({
                                                  partPoint,
                                                  rpos: [top, left],
                                                }) => (
                                                  <div
                                                    key={`key_${partPoint}`}
                                                    className="cerp-pp absolute text-center text-base font-bold"
                                                    style={{ top, left }}
                                                  >
                                                    {setPartState(
                                                      _.get(data, partPoint)
                                                    )}
                                                  </div>
                                                )
                                              )}
                                            </div>
                                            <div className="relative">
                                              <img
                                                src={BPRight}
                                                style={{
                                                  minHeight: '70mm',
                                                  height: '70mm',
                                                  maxHeight: '70mm',
                                                }}
                                                className="m-0 p-0 opacity-70"
                                                alt={'차량이미지'}
                                              />
                                              {_.map(
                                                PARTS_RIGHT,
                                                ({
                                                  partPoint,
                                                  rpos: [top, left],
                                                }) => (
                                                  <div
                                                    key={`key_${partPoint}`}
                                                    className="cerp-pp absolute text-center text-base font-bold"
                                                    style={{ top, left }}
                                                  >
                                                    {setPartState(
                                                      _.get(data, partPoint)
                                                    )}
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td
                                          colSpan="2"
                                          className="border-right-dotted border-bottom-dotted"
                                        >
                                          ⑫ 사고이력{' '}
                                          <span className="text-xs">
                                            (유의사항 4 참조)
                                          </span>
                                        </td>
                                        <td
                                          colSpan="4"
                                          className="border-right-dotted border-bottom-dotted"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'ACDNT_AT',
                                                  '0'
                                                )}
                                              />
                                              없음
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'ACDNT_AT',
                                                  '1'
                                                )}
                                              />
                                              있음
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          colSpan="3"
                                          className="border-right-dotted border-bottom-dotted"
                                        >
                                          단순수리
                                        </td>
                                        <td
                                          colSpan="2"
                                          className="border-bottom-solid"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'SIMPL_REPAIR_AT',
                                                  '0'
                                                )}
                                              />
                                              없음
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  'SIMPL_REPAIR_AT',
                                                  '1'
                                                )}
                                              />
                                              있음
                                            </div>
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td
                                          colSpan="9"
                                          className="border-right-solid border-bottom-dotted"
                                        >
                                          ⑬ 교환,판금 등 이상 부위
                                        </td>
                                        <td
                                          colSpan="2"
                                          className="border-bottom-dotted text-center"
                                        >
                                          가격조사ㆍ산정액 및 특기사항
                                        </td>
                                      </tr>

                                      <tr>
                                        <td
                                          rowSpan="3"
                                          className="border-right-dotted border-bottom-dotted text-center"
                                        >
                                          외판부위
                                        </td>
                                        <td
                                          rowSpan="2"
                                          className="border-right-dotted border-bottom-dotted"
                                        >
                                          1랭크
                                        </td>
                                        <td
                                          colSpan="7"
                                          className="border-right-solid"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  's037',
                                                  ['2', '3', '4']
                                                )}
                                              />
                                              1. 후드
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  's038',
                                                  ['2', '3', '4']
                                                )}
                                              />
                                              2. 프론트휀더
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  's039',
                                                  ['2', '3', '4']
                                                )}
                                              />
                                              3. 도어
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  's040',
                                                  ['2', '3', '4']
                                                )}
                                              />
                                              4. 트렁크 리드
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          rowSpan="8"
                                          className="border-right-dotted text-right"
                                        >
                                          만원
                                        </td>
                                        <td rowSpan="8">
                                          <b> </b>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td
                                          colSpan="7"
                                          className="border-right-solid border-bottom-dotted"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  's041',
                                                  ['2', '3', '4']
                                                )}
                                              />
                                              5. 라디에이터서포트(볼트체결부품)
                                            </div>
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="border-right-dotted border-bottom-dotted">
                                          2랭크
                                        </td>
                                        <td
                                          colSpan="7"
                                          className="border-right-solid border-bottom-dotted"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  's043',
                                                  ['2', '3', '4']
                                                )}
                                              />
                                              6. 쿼터패널(리어휀더)
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  's042',
                                                  ['2', '3', '4']
                                                )}
                                              />
                                              7. 루프패널
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  's044',
                                                  ['2', '3', '4']
                                                )}
                                              />
                                              8. 사이드실패널
                                            </div>
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td
                                          rowSpan="5"
                                          className="border-right-dotted text-center"
                                        >
                                          주요골격
                                        </td>
                                        <td
                                          rowSpan="2"
                                          className="border-right-dotted border-bottom-dotted"
                                        >
                                          A랭크
                                        </td>
                                        <td
                                          colSpan="7"
                                          className="border-right-solid"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  's045',
                                                  ['2', '3', '4']
                                                )}
                                              />
                                              9. 프론트패널
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  's046',
                                                  ['2', '3', '4']
                                                )}
                                              />
                                              10. 크로스멤버
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  's047',
                                                  ['2', '3', '4']
                                                )}
                                              />
                                              11. 인사이드패널
                                            </div>
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td
                                          colSpan="7"
                                          className="border-right-solid border-bottom-dotted"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  's054',
                                                  ['2', '3', '4']
                                                )}
                                              />
                                              17. 트렁크플로어
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  's053',
                                                  ['2', '3', '4']
                                                )}
                                              />
                                              18. 리어패널
                                            </div>
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td
                                          rowSpan="2"
                                          className="border-right-dotted border-bottom-dotted"
                                        >
                                          B랭크
                                        </td>
                                        <td
                                          colSpan="7"
                                          className="border-right-solid border-bottom-dotted"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  's048',
                                                  ['2', '3', '4']
                                                )}
                                              />
                                              12. 사이드멤버
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  's049',
                                                  ['2', '3', '4']
                                                )}
                                              />
                                              13. 휠하우스
                                            </div>
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td
                                          colSpan="7"
                                          className="border-right-solid border-bottom-dotted"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  's052',
                                                  ['2', '3', '4']
                                                )}
                                              />
                                              14. 필러패널
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-2">
                                              <div>(</div>
                                              <div className="flex flex-row align-items-center justify-content-start gap-1">
                                                <img
                                                  className="cbi"
                                                  alt="checkbox"
                                                  src={setSubCheckState(
                                                    data,
                                                    ['pp_63', 'pp_78'],
                                                    ['2', '3', '4']
                                                  )}
                                                />
                                                A,
                                              </div>
                                              <div className="flex flex-row align-items-center justify-content-start gap-1">
                                                <img
                                                  className="cbi"
                                                  alt="checkbox"
                                                  src={setSubCheckState(
                                                    data,
                                                    ['pp_103', 'pp_118'],
                                                    ['2', '3', '4']
                                                  )}
                                                />
                                                B,
                                              </div>
                                              <div className="flex flex-row align-items-center justify-content-start gap-1">
                                                <img
                                                  className="cbi"
                                                  alt="checkbox"
                                                  src={setSubCheckState(
                                                    data,
                                                    ['pp_144', 'pp_137'],
                                                    ['2', '3', '4']
                                                  )}
                                                />
                                                C
                                              </div>
                                              <div>)</div>
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  's056',
                                                  ['2', '3', '4']
                                                )}
                                              />
                                              19. 패키지트레이
                                            </div>
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="border-right-dotted">
                                          C랭크
                                        </td>
                                        <td
                                          colSpan="7"
                                          className="border-right-solid"
                                        >
                                          <div className="flex flex-row align-items-center justify-content-start gap-3">
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  's050',
                                                  ['2', '3', '4']
                                                )}
                                              />
                                              15. 대쉬패널
                                            </div>
                                            <div className="flex flex-row align-items-center justify-content-start gap-1">
                                              <img
                                                className="cbi"
                                                alt="checkbox"
                                                src={setCheckState(
                                                  data,
                                                  's051',
                                                  ['2', '3', '4']
                                                )}
                                              />
                                              16. 플로어패널
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr className="h-1rem">
                <td colSpan="2" className="text-xs text-right">
                  210mm×297mm[백상지 80g/㎡ 또는 중질지 80g/㎡]
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="paper">
          <table className="w-full h-full">
            <thead />
            <tbody>
              <tr className="h-1rem">
                <td className="text-xs" />
                <td className="text-xs text-right">(2/4쪽)</td>
              </tr>

              <tr>
                <td colSpan="2" className="content">
                  <table className="w-full h-full">
                    <thead />
                    <tbody>
                      <tr>
                        <td className="p-0 sub-title">
                          <table className="w-full">
                            <thead />
                            <tbody>
                              <tr>
                                <td className="pt-1 font-bold text-xl text-center line-height-1">
                                  자동차 세부상태
                                </td>
                              </tr>

                              <tr>
                                <td className="text-center">
                                  <div className="text-xs">
                                    (가격조사ㆍ산정액 및 특기사항은 매수인이
                                    자동차가격조사ㆍ산정을 원하는 경우에만
                                    적습니다)
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td className="p-0">
                          <table className="w-full h-full text-sm">
                            <colgroup>
                              <col width="19.77349943mm" />
                              <col width="24.16761042mm" />
                              <col width="17.57644394mm" />
                              <col width="28.5617214mm" />
                              <col width="13.18233296mm" />
                              <col width="2.197055493mm" />
                              <col width="6.591166478mm" />
                              <col width="17.57644394mm" />
                              <col width="26.36466591mm" />
                              <col width="17.57644394mm" />
                              <col width="20.43261608mm" />
                            </colgroup>

                            <thead />
                            <tbody>
                              <tr>
                                <td className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold">
                                  ⑭ 주요장치
                                </td>
                                <td
                                  colSpan="3"
                                  className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold text-center"
                                >
                                  항목 / 해당부품
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted bg-gray-100 font-semibold text-center"
                                >
                                  상태
                                </td>
                                <td
                                  colSpan="2"
                                  className="border-bottom-dotted bg-gray-100 font-semibold text-center"
                                >
                                  가격조사ㆍ산정액 및 특기사항
                                </td>
                              </tr>

                              <tr>
                                <td
                                  rowSpan="2"
                                  className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold text-center"
                                >
                                  자기진단
                                </td>
                                <td
                                  colSpan="3"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  원동기
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 1, paddingBottom: 1 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(
                                          data,
                                          'ENGINE_STBLT_AT',
                                          '1'
                                        )}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(
                                          data,
                                          'ENGINE_STBLT_AT',
                                          '2'
                                        )}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td
                                  rowSpan="2"
                                  className="border-right-dotted border-bottom-dotted text-right"
                                >
                                  만원
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="3"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  변속기
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 1, paddingBottom: 1 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(
                                          data,
                                          'GRBX_STBLT_AT',
                                          '1'
                                        )}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(
                                          data,
                                          'GRBX_STBLT_AT',
                                          '2'
                                        )}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  rowSpan="10"
                                  className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold text-center"
                                >
                                  원동기
                                </td>
                                <td
                                  colSpan="3"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  작동상태(공회전)
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 1, paddingBottom: 1 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's001', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's001', '5')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td
                                  rowSpan="10"
                                  className="border-right-dotted border-bottom-dotted text-right"
                                >
                                  만원
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  rowSpan="3"
                                  className="border-right-dotted border-bottom-dotted text-center"
                                >
                                  오일누유
                                </td>
                                <td
                                  colSpan="2"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  실린더 커버(로커암 커버)
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 1, paddingBottom: 1 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's057', '1')}
                                      />
                                      없음
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's057', '2')}
                                      />
                                      미세누유
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's057', '3')}
                                      />
                                      누유
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="2"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  실린더 헤드 / 가스켓
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 1, paddingBottom: 1 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's003', '1')}
                                      />
                                      없음
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's003', '2')}
                                      />
                                      미세누유
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's003', '3')}
                                      />
                                      누유
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="2"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  실린더 블록 / 오일팬
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 1, paddingBottom: 1 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's058', '1')}
                                      />
                                      없음
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's058', '2')}
                                      />
                                      미세누유
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's058', '3')}
                                      />
                                      누유
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="3"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  오일 유량
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's005', '1')}
                                      />
                                      적정
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's005', '2')}
                                      />
                                      부족
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  rowSpan="4"
                                  className="border-right-dotted border-bottom-dotted text-center"
                                >
                                  냉각수누수
                                </td>
                                <td
                                  colSpan="2"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  실린더 헤드 / 가스켓
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 1, paddingBottom: 1 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's007', '1')}
                                      />
                                      없음
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's007', '2')}
                                      />
                                      미세누수
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's007', '3')}
                                      />
                                      누수
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="2"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  워터펌프
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's008', '1')}
                                      />
                                      없음
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's008', '2')}
                                      />
                                      미세누수
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's008', '3')}
                                      />
                                      누수
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="2"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  라디에이터
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's009', '1')}
                                      />
                                      없음
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's009', '2')}
                                      />
                                      미세누수
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's009', '3')}
                                      />
                                      누수
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="2"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  냉각수 수량
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's010', '1')}
                                      />
                                      적정
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's010', '2')}
                                      />
                                      부족
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="3"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  커먼레일
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 1, paddingBottom: 1 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's011', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's011', '2')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  rowSpan="7"
                                  className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold text-center"
                                >
                                  변속기
                                </td>
                                <td
                                  rowSpan="3"
                                  className="border-right-dotted border-bottom-dotted text-center"
                                >
                                  자동변속기 (A/T)
                                </td>
                                <td
                                  colSpan="2"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  오일누유
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's012', '1')}
                                      />
                                      없음
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's012', '2')}
                                      />
                                      미세누유
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's012', '3')}
                                      />
                                      누유
                                    </div>
                                  </div>
                                </td>
                                <td
                                  rowSpan="7"
                                  className="border-right-dotted border-bottom-dotted text-right"
                                >
                                  만원
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="2"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  오일유량 및 상태
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's013', '1')}
                                      />
                                      적정
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's013', '2')}
                                      />
                                      부족
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's013', '3')}
                                      />
                                      과다
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="2"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  작동상태(공회전)
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's016', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's016', '6')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  rowSpan="4"
                                  className="border-right-dotted border-bottom-dotted text-center"
                                >
                                  수동변속기 (M/T)
                                </td>
                                <td
                                  colSpan="2"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  오일누유
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's017', '1')}
                                      />
                                      없음
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's017', '2')}
                                      />
                                      미세누유
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's017', '3')}
                                      />
                                      누유
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="2"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  기어변속장치
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's018', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's018', '5')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="2"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  오일유량 및 상태
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's019', '1')}
                                      />
                                      적정
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's019', '2')}
                                      />
                                      부족
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's019', '3')}
                                      />
                                      과다
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="2"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  작동상태(공회전)
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's020', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's020', '6')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  rowSpan="4"
                                  className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold text-center"
                                >
                                  동력전달
                                </td>
                                <td
                                  colSpan="3"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  클러치 어셈블리
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's021', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's021', '7')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td
                                  rowSpan="4"
                                  className="border-right-dotted border-bottom-dotted text-right"
                                >
                                  만원
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="3"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  등속조인트
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's022', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's022', '4')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="3"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  추진축 및 베어링
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's023', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's023', '5')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="3"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  디퍼렌셜 기어
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's060', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's060', '5')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  rowSpan="6"
                                  className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold text-center"
                                >
                                  조향
                                </td>
                                <td
                                  colSpan="3"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  동력조향 작동 오일 누유
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's024', '1')}
                                      />
                                      없음
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's024', '2')}
                                      />
                                      미세누유
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's024', '3')}
                                      />
                                      누유
                                    </div>
                                  </div>
                                </td>
                                <td
                                  rowSpan="6"
                                  className="border-right-dotted border-bottom-dotted text-right"
                                >
                                  만원
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  rowSpan="5"
                                  className="border-right-dotted border-bottom-dotted text-center"
                                >
                                  작동상태
                                </td>
                                <td
                                  colSpan="2"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  스티어링 펌프
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's026', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's026', '4')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="2"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  스티어링 기어(MDPS포함)
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's025', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's025', '5')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="2"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  스티어링조인트
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's061', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's061', '5')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="2"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  파워고압호스
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's062', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's062', '5')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="2"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  타이로드엔드 및 볼 조인트
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's027', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's027', '3')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  rowSpan="3"
                                  className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold text-center"
                                >
                                  제동
                                </td>
                                <td
                                  colSpan="3"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  브레이크 마스터 실린더오일 누유
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's059', '1')}
                                      />
                                      없음
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's059', '2')}
                                      />
                                      미세누유
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's059', '3')}
                                      />
                                      누유
                                    </div>
                                  </div>
                                </td>
                                <td
                                  rowSpan="3"
                                  className="border-right-dotted border-bottom-dotted text-right"
                                >
                                  만원
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="3"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  브레이크 오일 누유
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's029', '1')}
                                      />
                                      없음
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's029', '3')}
                                      />
                                      미세누유
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's029', '4')}
                                      />
                                      누유
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="3"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  배력장치 상태
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's030', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's030', '3')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  rowSpan="6"
                                  className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold text-center"
                                >
                                  전기
                                </td>
                                <td
                                  colSpan="3"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  발전기 출력
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's031', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's031', '3')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td
                                  rowSpan="6"
                                  className="border-right-dotted border-bottom-dotted text-right"
                                >
                                  만원
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="3"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  시동 모터
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's055', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's055', '3')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="3"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  와이퍼 모터 기능
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's032', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's032', '3')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="3"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  실내송풍 모터
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's033', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's033', '4')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="3"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  라디에이터 팬 모터
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's034', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's034', '4')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="3"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  윈도우 모터
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's036', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's036', '4')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  rowSpan="3"
                                  className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold text-center"
                                  style={{ wordBreak: 'keep-all' }}
                                >
                                  고전원 전기장치
                                </td>
                                <td
                                  colSpan="3"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  충전구 절연 상태
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's063', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's063', '5')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td
                                  rowSpan="3"
                                  className="border-right-dotted border-bottom-dotted text-right"
                                >
                                  만원
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="3"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  구동축전지 격리 상태
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's064', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's064', '5')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="3"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  고전원전기배선 상태 (접속단자, 피복, 보호기구)
                                </td>
                                <td
                                  colSpan="5"
                                  className="border-right-solid border-bottom-dotted"
                                  style={{ paddingTop: 2, paddingBottom: 2 }}
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's065', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's065', '5')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td className="border-bottom-dotted">
                                  <b> </b>
                                </td>
                              </tr>

                              <tr>
                                <td className="border-right-dotted bg-gray-100 font-semibold text-center">
                                  연료
                                </td>
                                <td colSpan="3" className="border-right-dotted">
                                  연료누출(LP가스포함)
                                </td>
                                <td colSpan="5" className="border-right-solid">
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's035', '1')}
                                      />
                                      없음
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 's035', '2')}
                                      />
                                      있음
                                    </div>
                                  </div>
                                </td>
                                <td className="border-right-dotted text-right">
                                  만원
                                </td>
                                <td>
                                  <b> </b>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr className="h-1rem">
                <td colSpan="2" className="text-xs text-right">
                  210mm×297mm[백상지 80g/㎡ 또는 중질지 80g/㎡]
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="paper">
          <table className="w-full h-full">
            <thead />
            <tbody>
              <tr className="h-1rem">
                <td className="text-xs" />
                <td className="text-xs text-right">(3/4쪽)</td>
              </tr>

              <tr>
                <td colSpan="2" className="content">
                  <table className="w-full h-full">
                    <thead />
                    <tbody>
                      <tr>
                        <td className="p-0 sub-title">
                          <table className="w-full">
                            <thead />
                            <tbody>
                              <tr>
                                <td className="pt-1 font-bold text-xl text-center line-height-1">
                                  자동차 기타정보
                                </td>
                              </tr>

                              <tr>
                                <td className="text-center">
                                  <div className="text-xs">
                                    (가격조사ㆍ산정액 및 특기사항은 매수인이
                                    자동차가격조사ㆍ산정을 원하는 경우에만
                                    적습니다)
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td className="p-0 sub-content">
                          <table className="w-full h-full text-sm">
                            <colgroup>
                              <col width="19.77349943mm" />
                              <col width="24.16761042mm" />
                              <col width="17.57644394mm" />
                              <col width="28.5617214mm" />
                              <col width="13.18233296mm" />
                              <col width="2.197055493mm" />
                              <col width="6.591166478mm" />
                              <col width="17.57644394mm" />
                              <col width="26.36466591mm" />
                              <col width="17.57644394mm" />
                              <col width="20.43261608mm" />
                            </colgroup>

                            <thead />
                            <tbody>
                              <tr>
                                <td className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold">
                                  &nbsp;
                                </td>
                                <td
                                  colSpan="9"
                                  className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold text-center"
                                >
                                  항목
                                </td>
                                <td
                                  className="border-bottom-dotted bg-gray-100 font-semibold text-center"
                                  style={{ letterSpacing: -2 }}
                                >
                                  가격조사ㆍ산정액
                                </td>
                              </tr>

                              <tr>
                                <td
                                  rowSpan="5"
                                  className="border-right-dotted border-bottom-dotted bg-gray-100 font-semibold text-center"
                                >
                                  수리필요
                                </td>
                                <td className="border-right-dotted border-bottom-dotted text-center">
                                  외장
                                </td>
                                <td
                                  colSpan="2"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 'TODO', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 'TODO', '1')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td
                                  colSpan="2"
                                  className="border-right-dotted border-bottom-dotted text-center"
                                >
                                  내장
                                </td>
                                <td
                                  colSpan="4"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 'TODO', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 'TODO', '1')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td rowSpan="6" className="text-right">
                                  만원
                                </td>
                              </tr>

                              <tr>
                                <td className="border-right-dotted border-bottom-dotted text-center">
                                  광택
                                </td>
                                <td
                                  colSpan="2"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 'TODO', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 'TODO', '1')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td
                                  colSpan="2"
                                  className="border-right-dotted border-bottom-dotted text-center"
                                >
                                  룸 크리닝
                                </td>
                                <td
                                  colSpan="4"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 'TODO', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 'TODO', '1')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td className="border-right-dotted border-bottom-dotted text-center">
                                  휠
                                </td>
                                <td
                                  colSpan="2"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 'TODO', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 'TODO', '1')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td
                                  colSpan="6"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-2">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <div className="mr-1">운전석</div>
                                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                                        <img
                                          className="cbi"
                                          alt="checkbox"
                                          src={setCheckState(data, 'TODO', '1')}
                                        />
                                        전,
                                      </div>
                                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                                        <img
                                          className="cbi"
                                          alt="checkbox"
                                          src={setCheckState(data, 'TODO', '1')}
                                        />
                                        후
                                      </div>
                                    </div>
                                    <div>&nbsp;/&nbsp;</div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <div className="mr-1">동반석</div>
                                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                                        <img
                                          className="cbi"
                                          alt="checkbox"
                                          src={setCheckState(data, 'TODO', '1')}
                                        />
                                        전,
                                      </div>
                                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                                        <img
                                          className="cbi"
                                          alt="checkbox"
                                          src={setCheckState(data, 'TODO', '1')}
                                        />
                                        후,
                                      </div>
                                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                                        <img
                                          className="cbi"
                                          alt="checkbox"
                                          src={setCheckState(data, 'TODO', '1')}
                                        />
                                        응급
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td className="border-right-dotted border-bottom-dotted text-center">
                                  타이어
                                </td>
                                <td
                                  colSpan="2"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 'TODO', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 'TODO', '1')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                                <td
                                  colSpan="6"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-2">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <div className="mr-1">운전석</div>
                                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                                        <img
                                          className="cbi"
                                          alt="checkbox"
                                          src={setCheckState(data, 'TODO', '1')}
                                        />
                                        전,
                                      </div>
                                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                                        <img
                                          className="cbi"
                                          alt="checkbox"
                                          src={setCheckState(data, 'TODO', '1')}
                                        />
                                        후
                                      </div>
                                    </div>
                                    <div>&nbsp;/&nbsp;</div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <div className="mr-1">동반석</div>
                                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                                        <img
                                          className="cbi"
                                          alt="checkbox"
                                          src={setCheckState(data, 'TODO', '1')}
                                        />
                                        전,
                                      </div>
                                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                                        <img
                                          className="cbi"
                                          alt="checkbox"
                                          src={setCheckState(data, 'TODO', '1')}
                                        />
                                        후,
                                      </div>
                                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                                        <img
                                          className="cbi"
                                          alt="checkbox"
                                          src={setCheckState(data, 'TODO', '1')}
                                        />
                                        응급
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td className="border-right-dotted border-bottom-dotted text-center">
                                  유리
                                </td>
                                <td
                                  colSpan="8"
                                  className="border-right-dotted border-bottom-dotted"
                                >
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 'TODO', '1')}
                                      />
                                      양호
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 'TODO', '1')}
                                      />
                                      불량
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td className="border-right-dotted bg-gray-100 font-semibold text-center">
                                  기본품목
                                </td>
                                <td className="border-right-dotted text-center">
                                  보유상태
                                </td>
                                <td colSpan="8" className="border-right-dotted">
                                  <div className="flex flex-row align-items-center justify-content-start gap-3">
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 'TODO', '1')}
                                      />
                                      있음
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-1">
                                      <img
                                        className="cbi"
                                        alt="checkbox"
                                        src={setCheckState(data, 'TODO', '1')}
                                      />
                                      없음
                                    </div>
                                    <div className="flex flex-row align-items-center justify-content-start gap-2">
                                      <div>(</div>
                                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                                        <img
                                          className="cbi"
                                          alt="checkbox"
                                          src={setCheckState(data, 'TODO', '1')}
                                        />
                                        사용설명서,
                                      </div>
                                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                                        <img
                                          className="cbi"
                                          alt="checkbox"
                                          src={setCheckState(data, 'TODO', '1')}
                                        />
                                        안전삼각대,
                                      </div>
                                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                                        <img
                                          className="cbi"
                                          alt="checkbox"
                                          src={setCheckState(data, 'TODO', '1')}
                                        />
                                        잭,
                                      </div>
                                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                                        <img
                                          className="cbi"
                                          alt="checkbox"
                                          src={setCheckState(data, 'TODO', '1')}
                                        />
                                        스패너
                                      </div>
                                      <div>)</div>
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="11"
                                  className="border-top-2 border-bottom-2 border-gray-400 py-2"
                                >
                                  <div className="flex flex-column align-items-center justify-content-center gap-1">
                                    <div className="flex flex-row align-items-center justify-content-center">
                                      <div className="text-lg font-semibold mr-2">
                                        최종 가격조사ㆍ산정 금액
                                      </div>
                                      <div className="flex flex-row align-items-center justify-content-between">
                                        <div
                                          style={{
                                            width: 22,
                                            border: 1,
                                            borderStyle: 'dotted',
                                          }}
                                        >
                                          {'\u00A0'}
                                        </div>
                                        <div
                                          style={{
                                            width: 22,
                                            borderTop: 1,
                                            borderRight: 1,
                                            borderBottom: 1,
                                            borderLeft: 0,
                                            borderStyle: 'dotted',
                                          }}
                                        >
                                          {'\u00A0'}
                                        </div>
                                        <div
                                          style={{
                                            width: 22,
                                            borderTop: 1,
                                            borderRight: 1,
                                            borderBottom: 1,
                                            borderLeft: 0,
                                            borderStyle: 'dotted',
                                          }}
                                        >
                                          {'\u00A0'}
                                        </div>
                                        <div
                                          style={{
                                            width: 22,
                                            borderTop: 1,
                                            borderRight: 1,
                                            borderBottom: 1,
                                            borderLeft: 0,
                                            borderStyle: 'dotted',
                                          }}
                                        >
                                          {'\u00A0'}
                                        </div>
                                        <div
                                          style={{
                                            width: 22,
                                            borderTop: 1,
                                            borderRight: 1,
                                            borderBottom: 1,
                                            borderLeft: 0,
                                            borderStyle: 'dotted',
                                          }}
                                        >
                                          {'\u00A0'}
                                        </div>
                                      </div>
                                      <div className="text-lg font-semibold mr-2">
                                        &nbsp;&nbsp;만원
                                      </div>
                                    </div>
                                    <div className="text-base text-center font-semibold line-height-2">
                                      <div>
                                        이 가격조사ㆍ산정가격은 보험개발원의
                                        차량기준가액을 바탕으로 한 기준가격과
                                      </div>
                                      <div className="flex flex-row align-items-center justify-content-center">
                                        <div className="mx-1">(</div>
                                        <img
                                          src={Unchecked}
                                          className="cbib mx-1"
                                          alt=""
                                        />{' '}
                                        기술사회,
                                        <img
                                          src={Unchecked}
                                          className="cbib mx-1"
                                          alt=""
                                        />{' '}
                                        한국자동차진단보증협회,
                                        <div className="mx-1">)</div>기준서를
                                        적용하였음
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  rowSpan="2"
                                  className="text-center font-semibold bg-gray-100"
                                >
                                  ⑮ 특기사항 및 점검자의 의견
                                </td>
                                <td className="border-left-dotted border-right-dotted text-center">
                                  성능ㆍ상태 점검자
                                </td>
                                <td
                                  colSpan="9"
                                  className="h-6rem vertical-align-top"
                                >
                                  {_.replace(
                                    _.get(data, 'ETC_MATTER'),
                                    /\n/g,
                                    ' / '
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td className="border-top-solid border-right-dotted border-left-solid text-center">
                                  가격ㆍ조사 산정자
                                </td>
                                <td colSpan="9" className="border-top-solid" />
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td className="p-0 sub-title">
                          <table className="w-full">
                            <thead />
                            <tbody>
                              <tr>
                                <td className="py-1 font-bold text-xl text-center line-height-1">
                                  {/* {`성능 점검 보증 수수료 : ${CommonUtil.Unit.format(
                                    Number(
                                      _.get(payment, 'totalInsurancePrice')
                                    )
                                  )} 원`} */}
                                  {`성능 점검 보증 수수료 : ${CommonUtil.Unit.format(
                                    Number(
                                      _.get(payment, 'insurancePrice') +
                                        _.get(payment, 'insurancePriceVAT')
                                    )
                                  )} 원`}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td className="p-0 sub-title">
                          <table className="w-full">
                            <thead />
                            <tbody>
                              <tr>
                                <td className="py-1 font-bold text-xl text-center line-height-1">
                                  유의사항
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td className="p-0">
                          <table className="w-full h-full text-sm">
                            <colgroup>
                              <col width="9.77349943mm" />
                              <col width="34.16761042mm" />
                              <col width="17.57644394mm" />
                              <col width="28.5617214mm" />
                              <col width="13.18233296mm" />
                              <col width="2.197055493mm" />
                              <col width="6.591166478mm" />
                              <col width="17.57644394mm" />
                              <col width="26.36466591mm" />
                              <col width="17.57644394mm" />
                              <col width="20.43261608mm" />
                            </colgroup>

                            <thead />
                            <tbody>
                              <tr>
                                <td
                                  colSpan="11"
                                  className="border-bottom-dotted"
                                >
                                  <span className="font-semibold underline">
                                    ※ 중고자동차성능·상태점검의 보증에 관한 사항
                                    등
                                  </span>
                                </td>
                              </tr>

                              <tr>
                                <td className="text-center vertical-align-top">
                                  1.
                                </td>
                                <td colSpan="10">
                                  <div className="flex flex-row align-items-start justify-content-start gap-2">
                                    <div className="text-xs">○</div>
                                    <div>
                                      자동차 매매업자는
                                      성능·상태점검기록부(가격조사·산정 부분
                                      제외)에 기재된 내용을 고지하지 아니하거나
                                      거짓으로 고지함으로써 매수인에게 재산상
                                      손해가 발생한 경우에는 그 손해를 배상할
                                      책임을 집니다.
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td />
                                <td colSpan="10">
                                  <div className="flex flex-row align-items-start justify-content-start gap-2">
                                    <div className="text-xs">○</div>
                                    <div>
                                      자동차성능상태점검자가 거짓 또는 오류가
                                      있는 성능상태점검 내용을 제공하여 아래의
                                      보증기간 또는 보증거리 이내에 자동차의
                                      실제 성능·상태가 다른 경우,
                                      자동차매매업자는 매수인의 재산상 손해를
                                      배상할 책임을 지며,
                                      자동차성능상태점검자에게 이를 구상할 수
                                      있습니다.(매수인이 성능상태점검자가 가입한
                                      책임보험 등을 통해 별도로 배상받는 경우는
                                      제외)
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td />
                                <td colSpan="10">
                                  <div className="flex flex-row align-items-start justify-content-start gap-2">
                                    <div className="text-xs">○</div>
                                    <div>
                                      자동차인도일부터 보증기간은 ( 30 )일,
                                      보증거리는 ( 2000 )킬로미터로 합니다.
                                      <br />
                                      (보증기간은 30일 이상, 보증거리는
                                      2천킬로미터 이상이어야 하며, 그 중 먼저
                                      도래한 것을 적용)
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td />
                                <td colSpan="10">
                                  <div className="flex flex-row align-items-start justify-content-start gap-2">
                                    <div className="text-xs">○</div>
                                    <div>
                                      자동차매매업자는 중고자동차
                                      성능·상태점검기록부를 매수인에게 고지할 때
                                      현행 자동차성능·상태점검자의
                                      보증범위(국토교통부 고시)를 첨부하여
                                      고지하여야 합니다. 동 보증범위는
                                      ‘자동차성능·상태점검자의
                                      보증범위’(국토교통부 고시)에 따르며,
                                      법제처 국가법령정보센터 또는 국토교통부
                                      홈페이지에서 확인할 수 있습니다.
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td />
                                <td colSpan="10">
                                  <div className="flex flex-row align-items-start justify-content-start gap-2">
                                    <div className="text-xs">○</div>
                                    <div className="font-semibold">
                                      자동차의 리콜에 관한 사항은
                                      자동차리콜센터(www.car.go.kr)에서 확인할
                                      수 있습니다
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td />
                                <td colSpan="10">
                                  <div className="flex flex-row align-items-start justify-content-start gap-2">
                                    <div className="text-xs">○</div>
                                    <div className="font-semibold">
                                      자동차365(www.car365.go.kr)에서 실매물
                                      조회 및 정비이력 확인을 할 수 있습니다.
                                      <br />- 자동차365 `{'>'}` 자동차
                                      실매물검색 `{'>'}` 차량번호 입력
                                      <br />- 자동차365 `{'>'}` 자동차 이력조회
                                      `{'>'}` 매매용차량조회 `{'>'}`
                                      자동차등록번호 검색
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td className="py-2 text-center vertical-align-top">
                                  2.
                                </td>
                                <td colSpan="10" className="py-2">
                                  중고자동차의 구조·장치 등의 성능·상태를
                                  고지하지 아니한 자, 거짓으로 점검하거나 거짓
                                  고지한 자는 「자동차관리법」 제80조제6호 및
                                  제7호에 따라 2년 이하의 징역 또는 2천만원
                                  이하의 벌금에 처합니다.
                                </td>
                              </tr>
                              <tr>
                                <td className="py-2 text-center vertical-align-top">
                                  3.
                                </td>
                                <td colSpan="10" className="py-2">
                                  성능·상태점검자(자동차정비업자)가 거짓으로
                                  성능·상태 점검을 하거나 점검한 내용과 다르게
                                  자동차매매업자에게 알린 경우 「자동차관리법
                                  제21조제2항 등의 규정에 따른 행정처분의 기준과
                                  절차에 관한 규칙」 제5조제1항에 따라 1차
                                  사업정지 30일, 2차 사업정지 90일, 3차
                                  등록취소의 행정처분을 받습니다.
                                </td>
                              </tr>

                              <tr>
                                <td className="py-2 text-center vertical-align-top">
                                  4.
                                </td>
                                <td colSpan="10" className="py-2">
                                  ⑫ 사고이력 인정은 사고로 자동차 주요 골격
                                  부위의 판금, 용접수리 및 교환이 있는 경우로
                                  한정합니다. 단, 쿼터패널, 루프패널,
                                  사이드실패널 부위는 절단, 용접 시에만 사고로
                                  표기합니다. (후드, 프론트펜더, 도어,
                                  트렁크리드 등 외판 부위 및 범퍼에 대한 판금,
                                  용접수리 및 교환은 단순수리로서 사고에
                                  포함되지 않습니다)
                                </td>
                              </tr>

                              <tr>
                                <td className="py-2 text-center vertical-align-top">
                                  5.
                                </td>
                                <td colSpan="10" className="py-2">
                                  성능·상태점검은 국토교통부장관이 정하는
                                  자동차성능·상태점검 방법에 따라야 합니다.
                                </td>
                              </tr>

                              <tr>
                                <td className="py-2 text-center vertical-align-top">
                                  6.
                                </td>
                                <td colSpan="10" className="py-2">
                                  체크항목 판단기준(예시)
                                  <div className="flex flex-row align-items-start justify-content-start gap-2">
                                    <div className="text-xs">○</div>
                                    <div>
                                      미세누유(미세누수): 해당부위에
                                      오일(냉각수)이 비치는 정도로서 부품 노후로
                                      인한 현상
                                    </div>
                                  </div>
                                  <div className="flex flex-row align-items-start justify-content-start gap-2">
                                    <div className="text-xs">○</div>
                                    <div>
                                      누유(누수): 해당부위에서 오일(냉각수)이
                                      맺혀서 떨어지는 상태
                                    </div>
                                  </div>
                                  <div className="flex flex-row align-items-start justify-content-start gap-2">
                                    <div className="text-xs">○</div>
                                    <div>
                                      부식: 차량하부와 외판의 금속표면이
                                      화학반응에 의해 금속이 아닌 상태로
                                      상실되어 가는 현상(단순히 녹슬어 있는
                                      상태는 제외합니다)
                                    </div>
                                  </div>
                                  <div className="flex flex-row align-items-start justify-content-start gap-2">
                                    <div className="text-xs">○</div>
                                    <div>
                                      침수: 자동차의 원동기, 변속기 등 주요장치
                                      일부가 물에 잠긴 흔적이 있는 상태
                                    </div>
                                  </div>
                                  <div className="flex flex-row align-items-start justify-content-start gap-2">
                                    <div className="text-xs">○</div>
                                    <div>
                                      현재 주행거리: 성능·상태점검 당시 해당
                                      차량 주행거리계의 주행거리를 기록하되,
                                      기록한 주행거리가
                                      자동차전산정보처리조직(자동차관리정보시스템)으로부터
                                      받은 주행거리(주행거리계 교체 정보
                                      포함)보다 적은 경우 ‘특기사항 및 점검자의
                                      의견’ 란에 이를 적어야 합니다.
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr className="h-1rem">
                <td colSpan="2" className="text-xs text-right">
                  210mm×297mm[백상지 80g/㎡ 또는 중질지 80g/㎡]
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="paper">
          <table className="w-full h-full">
            <thead />
            <tbody>
              <tr className="h-1rem">
                <td className="text-xs" />
                <td className="text-xs text-right">(4/4쪽)</td>
              </tr>

              <tr>
                <td colSpan="2" className="content">
                  <table className="w-full h-full">
                    <thead />
                    <tbody>
                      <tr>
                        <td className="p-0">
                          <table className="w-full text-sm">
                            <colgroup>
                              <col width="9.77349943mm" />
                              <col width="34.16761042mm" />
                              <col width="17.57644394mm" />
                              <col width="28.5617214mm" />
                              <col width="13.18233296mm" />
                              <col width="2.197055493mm" />
                              <col width="6.591166478mm" />
                              <col width="17.57644394mm" />
                              <col width="26.36466591mm" />
                              <col width="17.57644394mm" />
                              <col width="20.43261608mm" />
                            </colgroup>

                            <thead />
                            <tbody>
                              <tr>
                                <td
                                  colSpan="11"
                                  className="border-bottom-dotted"
                                >
                                  <span className="font-semibold underline">
                                    ※ 자동차가격조사·산정의 보증에 관한 사항 등
                                  </span>
                                </td>
                              </tr>

                              <tr>
                                <td className="py-2 text-center vertical-align-top">
                                  7.
                                </td>
                                <td colSpan="10" className="py-2">
                                  <div className="flex flex-row align-items-start justify-content-start gap-2">
                                    가격조사·산정자는 아래의 보증기간 또는
                                    보증거리 이내에 중고자동차
                                    성능·상태점검기록부(가격조사·산정 부분
                                    한정)에 적힌 내용에 허위 또는 오류가 있는
                                    경우 계약 또는 관계법령에 따라 매수인에
                                    대하여 책임을 집니다.
                                  </div>
                                  <div className="flex flex-row align-items-start justify-content-start gap-2">
                                    · 자동차인도일부터 보증기간은 ( )일,
                                    보증거리는 ( )킬로미터로 합니다.
                                  </div>
                                  <div className="flex flex-row align-items-start justify-content-start gap-2">
                                    (보증기간은 30일 이상, 보증거리는
                                    2천킬로미터 이상이어야 하며, 그 중 먼저
                                    도래한 것을 적용합니다)
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td className="py-2 text-center vertical-align-top">
                                  8.
                                </td>
                                <td colSpan="10" className="py-2">
                                  매매업자는 매수인이 가격조사·산정을 원할 경우
                                  가격조사·산정자가 해당 자동차 가격을
                                  조사·산정하여 결과를 이 서식에 적도록 한 후,
                                  반드시 매매계약을 체결하기 전에 매수인에게
                                  서면으로 고지하여야 합니다. 이 경우 매매업자는
                                  가격조사·산정자에게 가격조사·산정을 의뢰하기
                                  전에 매수인에게 가격조사·산정 비용을
                                  안내하여야 합니다.
                                </td>
                              </tr>

                              <tr>
                                <td className="py-2 text-center vertical-align-top">
                                  9.
                                </td>
                                <td colSpan="10" className="py-2">
                                  자동차가격은 보험개발원이 정한 차량기준가액을
                                  기준가격으로 조사·산정하되, 기준서는
                                  「자동차관리법」 제58조의4제1호에 해당하는
                                  자는 기술사회에서 발행한 기준서를, 제2호에
                                  해당하는 자는 한국자동차진단보증협회에서
                                  발행한 기준서를 각각 적용하여야 하며,
                                  기준가격과 기준서는 산정일 기준 가장 최근에
                                  발행된 것을 적용합니다.
                                </td>
                              </tr>

                              <tr>
                                <td className="py-2 text-center vertical-align-top">
                                  10.
                                </td>
                                <td colSpan="10" className="py-2">
                                  특기사항란은 가격조사·산정자의 자동차
                                  성능·상태에 대한 견해가 성능·상태점검자의
                                  견해와 다를 경우 표시합니다.
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td className="p-0">
                          <table className="w-full text-sm my-2">
                            <colgroup>
                              <col width="4mm" />
                              <col width="62mm" />
                              <col width="62mm" />
                              <col width="62mm" />
                              <col width="4mm" />
                            </colgroup>

                            <thead />
                            <tbody>
                              <tr>
                                <td>&nbsp;</td>
                                <td className="border-bottom-solid" />
                                <td
                                  rowSpan="2"
                                  className="border-top-solid border-right-solid border-bottom-solid border-left-solid text-center text-base"
                                >
                                  자동차가격조사·산정이란
                                </td>
                                <td className="border-bottom-solid" />
                                <td>&nbsp;</td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td className="border-left-solid" />
                                <td className="border-right-solid" />
                                <td>&nbsp;</td>
                              </tr>
                              <tr>
                                <td />
                                <td
                                  colSpan="3"
                                  className="border-right-solid border-bottom-solid border-left-solid px-3 py-1"
                                >
                                  <div className="flex flex-row align-items-start justify-content-center gap-2">
                                    <div>※</div>
                                    <div
                                      className="text-base"
                                      style={{ wordBreak: 'keep-all' }}
                                    >
                                      "
                                      <span className="font-semibold">
                                        가격조사·산정
                                      </span>
                                      "은 소비자가 매매계약을 체결함에 있어{' '}
                                      <span className="font-semibold">
                                        중고차 가격의 적절성 판단
                                      </span>
                                      에 참고할 수 있도록 법령에 의한 절차와
                                      기준에 따라{' '}
                                      <span className="font-semibold">
                                        전문 가격조사·산정인
                                      </span>
                                      이{' '}
                                      <span className="font-semibold">
                                        객관적으로 제시한 가액
                                      </span>
                                      입니다. 따라서 "가격조사·산정"은 소비자의{' '}
                                      <span className="font-semibold">
                                        자율적 선택에 따른 서비스
                                      </span>
                                      이며, 가격조사·산정 결과는 중고차
                                      가격판단에 관하여{' '}
                                      <span className="font-semibold">
                                        법적 구속력은 없고 소비자의 구매여부
                                        결정에 참고자료로 활용
                                      </span>
                                      됩니다.
                                    </div>
                                  </div>
                                </td>
                                <td />
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td className="p-0">
                          <table className="w-full bg-gray-300 sub-title">
                            <thead />
                            <tbody>
                              <tr>
                                <td className="border-top-2 border-gray-400 py-1 font-bold text-xl text-center line-height-1">
                                  점검 장면 촬영 사진
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td className="p-0">
                          <table className="w-full text-sm">
                            <colgroup>
                              <col width="97mm" />
                              <col width="97mm" />
                            </colgroup>

                            <thead />
                            <tbody>
                              <tr>
                                {images &&
                                  images.length > 0 &&
                                  images.map((photo, idx) => {
                                    if (!photo) return null;
                                    const {
                                      originalFileName,
                                      src,
                                      alt,
                                      photoId,
                                    } = photo;
                                    return (
                                      <td
                                        key={`performance_check_img_${
                                          originalFileName + idx + photoId
                                        }`}
                                      >
                                        <div className="flex flex-column align-items-center justify-content-center p-1">
                                          <img
                                            id={originalFileName + photoId}
                                            src={src}
                                            className="w-full p-0 m-0"
                                            alt={alt}
                                            style={{ maxHeight: 265 }}
                                          />
                                        </div>
                                      </td>
                                    );
                                  })}
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td className="p-0">
                          <table className="w-full bg-gray-300 sub-title">
                            <thead />
                            <tbody>
                              <tr>
                                <td className="border-top-2 border-gray-400 py-1 font-bold text-xl text-center line-height-1">
                                  서명
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td className="p-0">
                          <table className="w-full text-sm">
                            <thead />
                            <tbody>
                              <tr>
                                <td className="border-bottom-solid">
                                  <div className="flex flex-column align-items-start justify-content-start px-3 py-2 gap-2">
                                    <div className="w-full text-lg">
                                      「자동차관리법」 제58조 및 같은 법
                                      시행규칙 제120조에 따라
                                      <div className="ml-5 flex flex-row align-items-center justify-content-start">
                                        <div className="mx-1">(</div>
                                        <img
                                          src={Checked}
                                          className="cbib mx-1"
                                          alt=""
                                        />
                                        중고자동차성능·상태를 점검,&nbsp;
                                        <img
                                          src={Unchecked}
                                          className="cbib mx-1"
                                          alt=""
                                        />
                                        자동차가격조사 · 산정
                                        <div className="mx-1">)</div>하였음을
                                        확인합니다.
                                      </div>
                                    </div>
                                    <div className="w-full text-lg flex flex-row align-items-center justify-content-end">
                                      <div className="flex flex-row align-items-center justify-content-between gap-3">
                                        {_.get(data, 'CHCK_DE') ? (
                                          <div className="z-5 flex gap-1 align-items-center">
                                            <div className="w-4rem text-right">
                                              {dayjs(
                                                _.get(data, 'CHCK_DE')
                                              ).format('YYYY')}{' '}
                                              년
                                            </div>
                                            <div className="w-3rem text-right">
                                              {dayjs(
                                                _.get(data, 'CHCK_DE')
                                              ).format('MM')}{' '}
                                              월
                                            </div>
                                            <div className="w-3rem text-right">
                                              {dayjs(
                                                _.get(data, 'CHCK_DE')
                                              ).format('DD')}{' '}
                                              일
                                            </div>
                                            <div className="text-right text-sm">
                                              <span>
                                                {`(유효기간 : ${getEndDate(
                                                  _.get(data, 'CHCK_DE')
                                                )} 까지)`}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="z-5 flex gap-1 align-items-center">
                                            <div className="w-4rem text-right">
                                              {' '}
                                              년
                                            </div>
                                            <div className="w-3rem text-right">
                                              {' '}
                                              월
                                            </div>
                                            <div className="w-3rem text-right">
                                              {' '}
                                              일
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="w-full flex flex-column align-items-start justify-content-center px-5">
                                      <div className="w-full flex flex-row align-items-end justify-content-between my-1">
                                        <div className="text-lg">
                                          중고자동차 성능·상태 점검자
                                        </div>
                                        {renderSignImages()}
                                      </div>
                                      <div className="w-full flex flex-row align-items-end justify-content-between my-1">
                                        <div className="text-lg">
                                          자동차가격조사 · 산정자
                                        </div>
                                        <div className="vertical-align-bottom z-5">
                                          (서명 또는 인)
                                        </div>
                                      </div>
                                      <div className="w-full flex flex-row align-items-end justify-content-between my-1">
                                        <div className="text-lg">
                                          중고자동차 성능·상태 고지자
                                        </div>
                                        <div className="vertical-align-bottom">
                                          <span className="text-lg mr-4">
                                            {/* {_.get(data, 'NTIC_ENTRPS_NM')}{' '} */}
                                            {/* {_.get(data, 'NTIC_PERSON_NM')} */}
                                            {/* {_.get(
                                              customerCompany,
                                              'customerCompanyName'
                                            )} */}
                                            {_.get(
                                              customerCompany,
                                              'companyName'
                                            )}
                                          </span>{' '}
                                          (서명 또는 인)
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <div className="flex flex-column align-items-start justify-content-start p-3 gap-2">
                                    <div className="w-full text-lg">
                                      <div className="flex flex-row align-items-center justify-content-start">
                                        본인은 위 중고자동차성능 ·
                                        상태점검기록부
                                        <div className="mx-1">(</div>
                                        <img
                                          src={Unchecked}
                                          className="cbib mx-1"
                                          alt=""
                                        />
                                        자동차가격조사 · 산정 선택
                                        <div className="mx-1">)</div>를 발급받은
                                        사실을 확인합니다.
                                      </div>
                                    </div>
                                    <div className="w-full text-lg flex flex-row align-items-center justify-content-end">
                                      <div className="flex flex-row align-items-center justify-content-between gap-3">
                                        <div className="w-3rem text-right">
                                          {' '}
                                          년
                                        </div>
                                        <div className="w-3rem text-right">
                                          {' '}
                                          월
                                        </div>
                                        <div className="w-3rem text-right">
                                          {' '}
                                          일
                                        </div>
                                      </div>
                                    </div>
                                    <div className="w-full flex flex-column align-items-start justify-content-center px-5">
                                      <div className="w-full flex flex-row align-items-end justify-content-between">
                                        <div className="text-lg">&nbsp;</div>
                                        <div>
                                          <span className="text-lg mr-4">
                                            매수인
                                          </span>{' '}
                                          (서명 또는 인)
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr className="h-1rem">
                <td colSpan="2" className="text-xs text-right">
                  210mm×297mm[백상지 80g/㎡ 또는 중질지 80g/㎡]
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
);

export default PerformanceCheckReportMobile;
