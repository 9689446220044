import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { CommonUtil } from '../../utils/commonUtil';
import { Dropdown } from 'primereact/dropdown';
import { useRecoilValueLoadable } from 'recoil';
import { myInfoSelector } from '../../recoil/selectors';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import dayjs from 'dayjs';
const SendSMS = ({
  onHide,
  smsData,
  setSmsData,
  customerInfo,
  showSmsType,
  postSms,
}) => {
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const aid = myInfoLoadable.contents.associationInfo?.associationId ?? '18';

  const makeContent = (type, customerInfo, partnerInfo, addDocs) => {
    let str = '';
    // console.log(customerInfo);
    if (addDocs) {
      str = addDocs
        .map((el) => '- ' + el)
        .toString()
        .replace(/,/g, '\n');
    }

    let checkP = 0;
    let insurP = 0;
    let totalP = 0;
    let checkDate = '';
    let count = 0;
    let carInfo = '';
    if (!!customerInfo?.paymentInfo) {
      if (customerInfo.paymentInfo.length > 1) {
        customerInfo.paymentInfo.map(
          (el) => (checkP = checkP + el.checkPriceTotal)
        );
        customerInfo.paymentInfo.map(
          (el) => (insurP = insurP + el.insurancePriceTotal)
        );
        totalP = checkP + insurP;
        count = `성능점검 총 ${customerInfo.paymentInfo.length}건`;
      } else {
        checkP = customerInfo.paymentInfo[0].checkPriceTotal;
        insurP = customerInfo.paymentInfo[0].insurancePriceTotal;
        totalP = checkP + insurP;
        checkDate = customerInfo.paymentInfo[0].checkDate;
        checkDate = dayjs(checkDate).format('YYYY-MM-DD');
        carInfo = `[${customerInfo.paymentInfo[0].licensePlateNumber}] ${customerInfo?.paymentInfo[0]?.carName}`;
      }
    }
    // console.log(checkP, insurP);

    switch (type) {
      case 'SAFE6_CUSTOMER':
        setSmsData({
          ...smsData,
          destAddr: `${customerInfo?.contact}`,
          // msgType: 'lms',
          msgSubType: 'TPA10',
          contents: `${
            customerInfo?.carNbr
          } 딜러님,\n위 차량은 세이프6 대상차량이며, ${
            customerInfo?.shopName ?? '매매상사'
          }와의 계약에 따라 국산(4만)/수입(15만) 를 아래 고정계좌로 입금 바랍니다.\n\n1. 가상고정계좌 ${
            customerInfo?.bankAccountNo && customerInfo?.bankName
              ? `${customerInfo?.bankAccountNo} (${customerInfo?.bankName})`
              : '(계좌번호 문의 필요)'
          }\n- 입금자명 : ${
            customerInfo?.carNbr
          } (차량번호 필수)\n\n2. 주의사항\n- 입금자명 이 불명확한 경우 차량이보증 제외 대상이 될수 있습니다.\n- 상사별 2회~3회차 입금이 확인이 되지 않으면, ${
            customerInfo?.shopName
          } 의 세이프6 계약이 종료됨을 알려드립니다.\n\n3. 추가 가입\n- a/s잔류 , 당사자거래된 위탁차량의 세이프6 가입을 원하시면 사무장님에게 이야기 주시면 처리 여부를 알수 있습니다.`,
        });
        break;
      case 'PAYMENT':
        setSmsData({
          ...smsData,
          msgType: 'lms',
          destAddr: `${customerInfo?.paymentInfo[0]?.customerContact}`,
          contents: `안녕하세요.\n${
            customerInfo?.paymentInfo[0]?.customerCompanyName
          } ${customerInfo?.paymentInfo[0]?.customerName}님, ${
            customerInfo?.shopName
          }입니다.\n\n${
            checkDate ? checkDate : count
          }\n${carInfo}\n- 성능비 : ${checkP
            ?.toString()
            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원\n- 보험료 : ${insurP
            ?.toString()
            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원\n- 합계금액 : ${totalP
            ?.toString()
            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원\n\n${
            customerInfo?.bankName
          } ${customerInfo?.bankAccount}\n${
            customerInfo?.bankAccountName
          }\n\n성능비 건별/월별 결제가능\n보험료 매도시(후불결제)\n${
            checkDate && '차량번호로 '
          }입금 부탁드립니다.`,
        });
        break;
      case 'REPORT':
        setSmsData({
          ...smsData,
          url: '',
          contents: `[성능점검 안내]\n\n안녕하세요?\n${customerInfo?.shop.shopName} 입니다.\n성능점검이 완료되었습니다.\n\n- 차량번호: ${customerInfo?.licensePlateNumber}\n- 성능점검번호: ${customerInfo?.PRFOMNC_CHCK_NO}\n\n성능점검에 최선을 다하겠습니다.\n감사합니다.`,
        });
        break;
      case 'APP_DOWNLOAD':
        setSmsData({
          ...smsData,
          msgType: 'lms',
          url: '',
          contents: `접수 방법 및 서류 안내 드립니다.\n\n★접수시 접수자명 차주이름으로 접수하셔야합니다★\n\n사용하시는 휴대폰 스토어에 맞는  URL 링크를 누르신 뒤 아래 기재되어 있는 서류를 촬영하시어 첨부 바랍니다.\n[플레이스토어]\n-\n[앱스토어]\n-\n\n<고객님께서 업로드 하셔야 할 서류 입니다.>\n1. 구매 고객 명의로 이전된 자동차등록증\n2. 계기판(현재날짜 및 시간)\n3. 차량 구매 시 작성한 수기 계약서(손으로 작성된 양도증명서)\n - 사업자 및 법인 명의 차량인 경우 사업자등록증 사본 추가 첨부(비영리단체 고유번호증 포함)\n\n<딜러님에게 요청해 주셔야 하는 서류 입니다.>\n딜러서류는 어플에서 접수할때 딜러에게 서류요청하기에 딜러 연락처 입력시 해당딜러에게 서류 요청 자동 발생됩니다.\n1. 업소보관용/이전등록용 매매계약서\n(관인계약서->좌측 상중하 상사직인, 우측 상하고객님 도장 또는 날인 필수 확인)\n2. 성능점검기록부 앞, 뒤\n - 마지막장 하단에 매매상사 직인 날인 및 자동차 인도일, 차주 성함, 서명 필수\n - 한 장씩 전체면이 다 나오게 촬영 후 첨부 부탁드립니다.\n3. 자동차 등록원부(갑)\n - 이전 등록 구분에 "당사자거래이전"은 보증대상 차량이 아닙니다. "매매업자거래이전" 으로 되어있어야 합니다.\n\n★중고차 성능배상책임 보험은 국토교통부 장관이 고시한 보증 범위(자동차 관리법 시행령 제13조의 3 제2항 관련)에 한하여 보상합니다. 단, 보증 범위 내 부품의 일부일 경우에도 소모성 부품 또는 일반적인 자동차 검사 방법으로 점검이 불가능한 경우에는 보상이 불가능합니다.`,
        });
        break;
      case 'REJECTION':
        setSmsData({
          ...smsData,
          destAddr: `${customerInfo.customerContact}`,
          msgType: 'at',
          msgSubType: 'TPA06',
          contents: `${
            customerInfo?.carNbr
          } 고객님, 안녕하세요. 자동차성능·상태점검책임보험의 보장기간이 경과하여 보증이 불가하여 안내 드립니다. 자세한 내용은 아래를 참고 부탁 드립니다.\n${
            !smsData.warrnaty.day ? '\n- 인도일자로부터 30일 경과' : ''
          }\n${
            !smsData.warrnaty.distance ? '- 주행거리 2,000Km 초과\n' : ''
          }\n*자동차성능·상태점검책임보험의 보장기간\n- 자동차 인도일로부터 30일 또는 주행거리 2천킬로미터 이내 중 먼저 도래한 기준 이내\n\n감사합니다.`,
        });
        break;
      case 'CUSTOMER_DOCS':
        setSmsData({
          ...smsData,
          msgType: 'at',
          msgSubType: 'TPA04',
          subject: smsData.subject ? smsData.subject : null,
          destAddr: `${customerInfo.customerContact}`,
          contents: `${customerInfo?.carNbr} 고객님, 안녕하세요.\n고객님께서 제출하신 서류를 검토하던 중, 일부 서류에 보완이 필요하여 다시 제출해 주시기를 부탁드리게 되었습니다.\n다시 제출이 필요한 서류는 다음과 같습니다.\n\n${str}\n\n감사합니다.`,
        });
        break;
      case 'INFO_TO_CUSTOMER':
        setSmsData({
          ...smsData,
          msgType: 'at',
          msgSubType: 'TPA03',
          subject: smsData.subject ? smsData.subject : null,
          destAddr: `${customerInfo.customerContact}`,
          contents: `${
            customerInfo?.carNbr
          } 고객님, 안녕하세요. 방문하실 진단점 정보 안내 드립니다.\n\n- 진단점명 : ${
            partnerInfo?.partnerName ?? ''
          }\n- 연락처 : ${partnerInfo?.cellphone ?? ''}\n- 위치 : ${
            partnerInfo?.address ?? ''
          }\n\n감사합니다.`,
        });
        break;
      case 'INFO_TO_PARTNER':
        setSmsData({
          ...smsData,
          msgType: 'at',
          msgSubType: 'TPA05',
          subject: '사진 업로드 하기',
          destAddr: `${partnerInfo?.cellphone}`,
          contents: `안녕하세요. 방문예정 고객님의 정보를 전달 드립니다. 함께 전달 드리는 URL 을 통해 진단 사진을 업로드해주세요.\r\n\r\n- 차량번호 : ${
            customerInfo?.carNbr
          }\r\n- 연락처 : ${CommonUtil.Formatter.phone(
            customerInfo.customerContact
          )}\r\n\r\n감사합니다.`,
          url: `${process.env.REACT_APP_BASE_URL}/upload/claim/aI=${aid}&carNum=${customerInfo?.carNbr}&cusN=${customerInfo?.customerName}&cusC=${customerInfo?.customerContact}&dealer=false&partner=true&pId=${partnerInfo?.partnerId}`,
        });
        break;
      case 'PARTNER_UPLOAD_URL':
        const approvedDoc = partnerInfo?.partnerDocuments?.find(
          (doc) => doc.documentCode === 'P_APPROVED'
        );
        const insuranceDoc = partnerInfo?.partnerDocuments?.find(
          (doc) => doc.documentCode === 'P_INSURANCE_TEMPLATE'
        );
        const baseUrl = process.env.REACT_APP_CLOUDFRONT_BASE_URL || '';
        const approvedLink = approvedDoc?.filePath
          ? `${baseUrl}${approvedDoc.filePath}`
          : '서류반영 버튼을 먼저 클릭해주세요.';
        const insuranceLink = insuranceDoc?.filePath
          ? `${baseUrl}${insuranceDoc.filePath}`
          : '서류반영 버튼을 먼저 클릭해주세요.';

        setSmsData({
          ...smsData,
          msgType: 'at',
          msgSubType: 'TPA08',
          subject: '사진 업로드 하기',
          contents: `안녕하세요. ${customerInfo?.carNbr} 차량에 대해 수리 승인이 되었습니다.\n\n전달드리는 아래 링크를 통해 서류를 다운받으신 뒤 서류작성 후 진단/수리점 APP 을 통해 업로드 부탁 드립니다.\n\n1. 승인견적서 다운로드\n${approvedLink}\n\n2. 보험금청구 동의서 다운로드\n${insuranceLink}`,
        });
        break;
      case 'PARTNER_UPLOAD_URL_FOR_APPLE':
        setSmsData({
          ...smsData,
          msgType: 'at',
          msgSubType: 'TPA07',
          subject: '사진 업로드 하기',
          contents: `안녕하세요. 전달 드리는 URL 을 통해 수리 사진을 업로드해주세요.\n\n- 차량번호 : ${customerInfo?.carNbr}\n\n감사합니다.`,
          url: `${process.env.REACT_APP_BASE_URL}/upload/claim/aI=${aid}&carNum=${customerInfo?.carNbr}&cusN=${customerInfo?.customerName}&cusC=${customerInfo?.customerContact}&dealer=false&partner=true&pId=${partnerInfo?.partnerId}`,
        });
        break;
      case 'DEALER_UPLOAD_URL':
        setSmsData({
          ...smsData,
          msgType: 'at',
          msgSubType: 'TPA02',
          subject: '서류 업로드 하기',
          contents: `안녕하세요. 전달 드리는 URL 을 통해 서류를 업로드해주세요.\n\n- 차량번호 : ${
            customerInfo?.carNbr
          }\n- 연락처 : ${CommonUtil.Formatter.phone(
            customerInfo?.customerContact
          )}\n\n[필요서류]\n${str}\n\n감사합니다.`,
          url: `${process.env.REACT_APP_BASE_URL}/upload/claim/aI=${aid}&carNum=${customerInfo?.carNbr}&cusN=${customerInfo?.customerName}&cusC=${customerInfo?.customerContact}&dealer=true&partner=false&pId=${partnerInfo?.partnerId}`,
        });
        break;
      default:
    }
  };

  const [partnerList, setPartnerList] = useState([]);
  const [partnerInfo, setPartnerInfo] = useState(null);
  const [addDocs, setAddDocs] = useState([]);

  const onAddDocsChange = (e) => {
    let _addDocs = [...addDocs];
    if (e.checked) _addDocs.push(e.value);
    else _addDocs.splice(_addDocs.indexOf(e.value), 1);
    setAddDocs(_addDocs);
  };

  useEffect(() => {
    if (!!aid && showSmsType && addDocs) {
      makeContent(showSmsType, customerInfo, null, addDocs);
    } else if (showSmsType === 'PAYMENT' || showSmsType === 'SAFE6_CUSTOMER') {
      makeContent(showSmsType, customerInfo);
    }
  }, [showSmsType, customerInfo, aid, addDocs]);

  useEffect(() => {
    if (smsData.partnerInfo) {
      setPartnerList(smsData.partnerInfo);
    }
  }, [smsData]);

  useEffect(() => {
    if (showSmsType && !!partnerInfo) {
      makeContent(showSmsType, customerInfo, partnerInfo);
    }
  }, [showSmsType, customerInfo, partnerInfo]);

  useEffect(() => {
    if (smsData.msgType === 'at') {
      if (
        showSmsType === 'PARTNER_UPLOAD_URL' ||
        showSmsType === 'PARTNER_UPLOAD_URL_FOR_APPLE'
      ) {
        setPartnerInfo(smsData.partnerInfo);
      }
      makeContent(showSmsType, customerInfo, partnerInfo, addDocs);
    }
  }, [smsData.msgType, partnerInfo]);

  return (
    <Dialog
      onHide={onHide}
      modal
      visible
      style={{ width: '340px' }}
      header={'메세지 전송 - ' + SMSType[showSmsType].title}
      footer={() => (
        <div className="flex flex-auto align-items-start justify-content-center pt-2">
          <Button
            label="전송하기"
            icon="pi pi-check-circle"
            className=" mr-1"
            onClick={() =>
              SMSType[showSmsType].title === '성능접수 안내' ||
              SMSType[showSmsType].title.includes('서류') ||
              SMSType[showSmsType].title.includes('거절') ||
              SMSType[showSmsType].title.includes('결제') ||
              SMSType[showSmsType].title.includes('수리')
                ? postSms(SMSType[showSmsType].title)
                : !!partnerInfo
                ? postSms(SMSType[showSmsType].title)
                : window.cerp.toast.warn('입고점을 선택해주세요.')
            }
          />
        </div>
      )}
    >
      {showSmsType === 'REPORT' || showSmsType === 'SAFE6_CUSTOMER' ? null : (
        <>
          <div className="">
            고객명 :{' '}
            {customerInfo?.customerName ||
              `[${customerInfo?.paymentInfo[0]?.customerCompanyName}] ${customerInfo?.paymentInfo[0]?.customerName}`}
          </div>
          <div className="my-2">
            연락처 :{' '}
            {CommonUtil.Formatter.phone(customerInfo?.customerContact) ||
              customerInfo?.paymentInfo[0]?.customerContact}
          </div>
          <Divider></Divider>
        </>
      )}
      <div className="flex align-items-center">
        <label>수신번호 :</label>
        <InputText
          value={CommonUtil.Formatter.phone(smsData.destAddr)}
          onChange={(e) => setSmsData({ ...smsData, destAddr: e.target.value })}
          className=" p-inputtext-sm ml-2"
          name="customerContact"
        />
      </div>
      <div className="flex align-items-center mt-1">
        <label>발송번호 :</label>
        <InputText
          value={CommonUtil.Formatter.phone(smsData.sourceAddr)}
          onChange={(e) =>
            setSmsData({ ...smsData, sourceAddr: e.target.value })
          }
          className=" p-inputtext-sm ml-2"
          name="customerContact"
        />
      </div>
      <div className="flex align-items-center mt-1">
        <label>발송방법 :</label>
        <div className="flex align-items-center mt-1">
          <RadioButton
            inputId="at"
            name="customerSearchBy"
            value="at"
            className="mx-2"
            checked={smsData.msgType === 'at'}
            onChange={(e) =>
              setSmsData({ ...smsData, msgType: e.target.value })
            }
          />
          <label htmlFor="at" className="mr-2">
            알림톡
          </label>
          <RadioButton
            inputId="lms"
            name="customerSearchBy"
            value="lms"
            className="mx-2"
            checked={smsData.msgType === 'lms'}
            onChange={(e) =>
              setSmsData({ ...smsData, msgType: e.target.value })
            }
          />
          <label htmlFor="lms">문자</label>
        </div>
      </div>
      <Divider />
      <div>발송내역</div>
      {showSmsType === 'INFO_TO_CUSTOMER' ||
      showSmsType === 'INFO_TO_PARTNER' ? (
        <div className="flex mt-2 align-items-center gap-4">
          <div>입고점 선택</div>
          <Dropdown
            value={partnerInfo}
            onChange={(e) => setPartnerInfo(e.value)}
            options={partnerList}
            optionLabel="partnerName"
            placeholder="입고점을 선택해주세요"
            className="w-13rem"
            emptyMessage="입고점이 없습니다."
          />
        </div>
      ) : null}
      {showSmsType === 'CUSTOMER_DOCS' && (
        <div className="mt-1">
          <div className="flex flex-wrap gap-2">
            {DOC_TYPE_CUSTOMER.map((el, idx) => (
              <div key={idx}>
                <Checkbox
                  inputId={el.value}
                  name={el.value}
                  value={el.label}
                  onChange={(e) => onAddDocsChange(e)}
                  checked={addDocs.includes(el.label)}
                />
                <label htmlFor={el.value} className="mx-2">
                  {el.label}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
      {showSmsType === 'DEALER_UPLOAD_URL' && (
        <div className="mt-1">
          <div className="flex flex-wrap gap-2">
            {DOC_TYPE_DEALER.map((el, idx) => (
              <div key={idx}>
                <Checkbox
                  inputId={el.value}
                  name={el.value}
                  value={el.label}
                  onChange={(e) => onAddDocsChange(e)}
                  checked={addDocs.includes(el.label)}
                />
                <label htmlFor={el.value} className="mx-2">
                  {el.label}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="mt-2">
        <InputTextarea
          disabled={smsData.msgType === 'at'}
          className="w-full mt-1"
          rows={4}
          autoResize
          value={smsData?.contents || ''}
          onChange={({ target: { value } }) =>
            setSmsData({ ...smsData, contents: value })
          }
          readOnly={showSmsType === 'REPORT'}
        />
      </div>
    </Dialog>
  );
};

export default SendSMS;

const SMSType = {
  APP_DOWNLOAD: {
    title: '성능접수 안내',
  },
  REJECTION: {
    title: '보증 거절 안내',
    content: '',
  },
  INFO_TO_CUSTOMER: {
    title: '입고점 정보 안내',
    content: '',
  },
  INFO_TO_PARTNER: {
    title: '진단점 고객정보 안내',
    content: '',
  },
  PARTNER_UPLOAD_URL: {
    title: '수리승인 및 양식 다운로드 안내',
    content: '',
  },
  PARTNER_UPLOAD_URL_FOR_APPLE: {
    title: '수리사진 업로드 안내',
    content: '',
  },
  DEALER_UPLOAD_URL: {
    title: '매매상사 서류 업로드 안내',
  },
  CUSTOMER_DOCS: {
    title: '고객 서류 재요청 안내',
  },
  REPORT: {
    title: '최종고지 리포트(요약)',
  },
  PAYMENT: {
    title: '결제정보 안내',
  },
  SAFE6_CUSTOMER: {
    title: '결제정보 안내',
  },
};

const DOC_TYPE_CUSTOMER = [
  {
    value: 'C_FRONT',
    label: '차량전면사진',
  },
  {
    value: 'C_DASHBOARD',
    label: '현재 기준 계기판 사진',
  },
  {
    value: 'C_CONTRACT',
    label: '매매계약서',
  },
  {
    value: 'C_CERTIFICATE',
    label: '차량등록증',
  },
  {
    value: 'C_ETC',
    label: '기타',
  },
];

const DOC_TYPE_DEALER = [
  {
    value: 'D_RECORD_1',
    label: '성능기록부',
  },
  {
    value: 'D_RECORD_2',
    label: '자동차등록원부(갑)',
  },
  {
    value: 'D_CONTRACT',
    label: '전산 매도용 계약서',
  },
];
